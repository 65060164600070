import { FormChildrenProp, FormValues } from "@castiero/web/schemas/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material";
import { defaults } from "lodash";
import { useTranslation } from "next-i18next";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MuiField } from "../fields/MuiField";
import { Col, Row } from "../grid";
import { FocusTrap } from "@mui/base";
import { Customer, CustomerSchema } from "@castiero/modules/schema/Customer";
import useAppStore from "../../hooks/useAppStore";
import byType from "@castiero/modules/schema/byType";
import { Dropdown } from "../fields/Dropdown";
import { ModalBox } from "../styled/modal";
import ModalHeader from "../MUI/modals/Header";

export type CustomerFormType = FormValues<Customer>;

export const initCustomer: CustomerFormType = {
  id: "",
  name: "",
  account_id: "",
  phone: "",
  notes: "",
};

export const CustomerForm: React.FC<FormChildrenProp<Customer>> = ({
  onSubmit,
  children,
  defaults: defaultValues,
  state,
  setState,
}) => {
  const { t } = useTranslation();
  const localDefaultValues = defaults(defaultValues, initCustomer);
  const methods = useForm<FormValues<Customer>>({
    resolver: yupResolver(CustomerSchema),
    defaultValues: localDefaultValues,
  });
  const [id] = methods.watch(["id"] as const);
  const { formState, reset } = methods;
  const { options } = useAppStore("accounts");

  const receivable = byType(options, "receivable");

  return (
    <FocusTrap disableAutoFocus open>
      <Modal
        open={!!state}
        onClose={() => setState?.(false)}
        disableEnforceFocus
      >
        <ModalBox>
          <ModalHeader
            title={
              defaultValues?.id ? t("edit_customer") : t("create_new_customer")
            }
            onClose={() => setState?.(false)}
          />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Row>
                <Col sm={12}>
                  <MuiField
                    label={t`name`}
                    name="name"
                    error={formState.errors.name?.message}
                  />
                </Col>
                <Col sm={12}>
                  <MuiField
                    label={t`phone`}
                    name="phone"
                    error={formState.errors.phone?.message}
                  />
                </Col>
                <Col sm={12}>
                  <Dropdown
                    id="customer-account"
                    label={t("portfolio")}
                    options={receivable}
                    name="account_id"
                    error={formState.errors.account_id?.message}
                  />
                </Col>
                <Col sm={12}>
                  <MuiField
                    label={t`notes`}
                    name="notes"
                    error={formState.errors.notes?.message}
                    multiline
                    rows={4}
                  />
                </Col>
                <Col xs={12}>
                  {children(
                    () => reset(localDefaultValues),
                    !id,
                    methods.formState
                  )}
                </Col>
              </Row>
            </form>
          </FormProvider>
        </ModalBox>
      </Modal>
    </FocusTrap>
  );
};
