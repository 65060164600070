import account, { AccountEventsType } from "./account";
import budget, { BudgetEventsTypes } from "./budget";
import transaction, { TransactionEventsType } from "./transaction";
import tag, { TagEventsType } from "./tag";
import user, { UserEventsType } from "./user";
import { eventEmitter } from "./eventEmitter";
import company, { CompanyEventsType } from "./company";
import customer, { CustomerEventsType } from "./customer";
import customerContact, { CustomerContactEventsType } from "./customerContact";

type EventTypes = AccountEventsType &
  BudgetEventsTypes &
  TransactionEventsType &
  TagEventsType &
  UserEventsType &
  CompanyEventsType &
  CustomerEventsType &
  CustomerContactEventsType;

export type EventKeys = keyof EventTypes;

export type EventPayload<T extends EventKeys> = EventTypes[T];

export default {
  emit: <T extends EventKeys>(key: T, payload: EventPayload<T>) =>
    eventEmitter.emit(key, payload),
  once: <T extends EventKeys>(
    key: T,
    cb: (payload: EventPayload<T>) => void | Promise<void>
  ): (() => void) => {
    eventEmitter.once(key, cb);
    return () => eventEmitter.off(key, cb);
  },
  on: <T extends EventKeys>(
    key: T,
    cb: (payload: EventPayload<T>) => void | Promise<void>
  ): (() => void) => {
    eventEmitter.on(key, cb);
    return () => eventEmitter.removeListener(key, cb);
  },
  off: <T extends EventKeys>(
    key: T,
    cb: (payload: EventPayload<T>) => void | Promise<void>
  ) => eventEmitter.removeListener(key, cb),
  removeAllListeners: <T extends EventKeys>(key: T) =>
    eventEmitter.removeAllListeners(key),

  ...account,
  ...budget,
  ...transaction,
  ...tag,
  ...user,
  ...company,
  ...customer,
  ...customerContact,
} as const;
