import { Account } from "@castiero/web/schemas/Account";

export interface ITransnational {
  value: number;
  readonly source?: Account;
  readonly target: Account;
  readonly monthly?: number;
}
export const isExpense = (t: ITransnational) =>
  t.source?.type === "debit" &&
  (t.target?.type === "cost" || t.target?.type === "credit");

export const isIncome = (t: ITransnational) =>
  !t.source && (t.target?.type === "debit" || t.target?.type === "credit");

export const isDecreaseDebit = (t: ITransnational) =>
  t.source?.type === "debit" && t.target.type === "credit";

export const isIncreaseDebit = (t: ITransnational) =>
  t.source?.type === "credit" &&
  (t.target?.type === "cost" || t.target?.type === "debit");

export const getTransactionType = (t: ITransnational, simple?: boolean) => {
  if (isExpense(t)) return "expense";
  if (isIncome(t)) return "income";
  if (isIncreaseDebit(t)) return simple ? "expense" : "loan";

  return simple ? "expense" : "transference";
};
