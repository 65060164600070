import { AppStateType, selectors } from "@castiero/web/store";
import { useSelector } from "react-redux";

type SelectorsType = typeof selectors;

export default function useAppStore<K extends keyof SelectorsType>(
  storeKey: K
) {
  return useSelector<AppStateType>(selectors[storeKey]) as ReturnType<
    SelectorsType[K]
  >;
}
