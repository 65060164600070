import { FC } from "react";
import { AccountFormProvider } from "./account";
import { BudgetFormProvider } from "./budget";
import { TransactionFormProvider } from "./transaction";
import { TagFormProvider } from "./tag";
import { CompanyFormProvider } from "./company";
import { ModelFormProvider } from "./importModel";
import { CustomerFormProvider } from "./customer";

export const FormProviders: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <ModelFormProvider>
    <TagFormProvider>
      <AccountFormProvider>
        <BudgetFormProvider>
          <CompanyFormProvider>
            <CustomerFormProvider>
              <TransactionFormProvider>{children}</TransactionFormProvider>
            </CustomerFormProvider>
          </CompanyFormProvider>
        </BudgetFormProvider>
      </AccountFormProvider>
    </TagFormProvider>
  </ModelFormProvider>
);
