import { useApiService } from "@castiero/web/api/apiService";
import { colors, theme } from "@castiero/web/common/theme";
import { toastSaveParams } from "@castiero/web/common/toast";
import {
  BudgetForm,
  BudgetFormType,
  getInitBudget,
} from "@castiero/web/components/forms/BudgetForm";
import { ButtonPrimary } from "@castiero/web/components/buttons";
import { Budget, BudgetCast } from "@castiero/web/schemas/Budget";
import { useTranslation } from "next-i18next";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Row, Col } from "react-grid-system";
import { toast } from "react-toastify";
import { FormContext } from "../common/types";
import useAppStore from "@castiero/web/hooks/useAppStore";
import events from "@castiero/web/common/events";

const BudgetFormContext = createContext<FormContext<Budget, BudgetFormType>>(
  undefined as never
);

export const BudgetFormProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState<BudgetFormType>();
  const toggle = useCallback(
    (_active: boolean, budget?: Partial<BudgetFormType>) => {
      setActive(_active != active);
      setValues(getInitBudget(budget));
    },
    [active]
  );

  const { t } = useTranslation();
  const apiService = useApiService();

  const state = useMemo(
    () => ({ active, values, toggle }),
    [active, values, toggle]
  );

  const isLoaded = [
    useAppStore("accounts").optStatus,
    useAppStore("tags").optStatus,
  ].every((s) => s == "loaded");

  useEffect(() => {
    if (state.active) {
      apiService.tags.getOptions();
      apiService.accounts.getOptions();
      events.on(events.ACCOUNT_CONTEXT_CHANGED, apiService.accounts.getOptions);
      events.on(events.TAG_CONTEXT_CHANGED, apiService.tags.getOptions);
    }
    return () => {
      events.off(
        events.ACCOUNT_CONTEXT_CHANGED,
        apiService.accounts.getOptions
      );
      events.off(events.TAG_CONTEXT_CHANGED, apiService.tags.getOptions);
    };
  }, [state.active]);

  const onSubmit = async (data: BudgetFormType) => {
    if (data.source_account_id === 0) {
      data = {
        ...data,
        source_account_id: undefined,
        type: "income",
      };
    }
    await toast.promise(
      apiService.budgets.save(BudgetCast(data)),
      toastSaveParams("budget", t)
    );
    setActive(false);
  };

  return (
    <BudgetFormContext.Provider value={state}>
      {children}
      {active && isLoaded ? (
        <BudgetForm
          defaults={values}
          state={true}
          setState={setActive}
          onSubmit={onSubmit}
        >
          {(reset, create) => (
            <Row>
              <Col>
                <ButtonPrimary
                  id="cancel-budget-form-button"
                  type="button"
                  onClick={() => {
                    reset();
                    setActive(false);
                  }}
                  style={{ backgroundColor: theme.palette.error.main }}
                >
                  {t("cancel")}
                </ButtonPrimary>
              </Col>
              <Col>
                <ButtonPrimary
                  id="create-budget-form-button"
                  type="submit"
                  style={{ backgroundColor: colors.success }}
                >
                  {!create ? t("save") : t("create")}
                </ButtonPrimary>
              </Col>
            </Row>
          )}
        </BudgetForm>
      ) : null}
    </BudgetFormContext.Provider>
  );
};

export const useBudgetForm = () => {
  const context = useContext(BudgetFormContext);
  if (!context) {
    throw new Error("useBudgetForm must be used within a BudgetFormProvider");
  }
  return context;
};
