import { DataStoreActionType } from "@castiero/modules/redux/createDataSlice";
import { ObjectStoreActionType } from "@castiero/modules/redux/createObjectStore";
import { AuthStoreType } from "@castiero/web/store/types";
import { AxiosResponse } from "axios";
import { PageNotFoundError } from "next/dist/shared/lib/utils";
import { Company } from "../../schemas/Company";
import { User, UserSettingsType } from "../../schemas/User";
import { ApiServiceDeps } from "./types";

export const authServiceProvider = (
  deps: ApiServiceDeps,
  authActions: ObjectStoreActionType<AuthStoreType>,
  companiesActions: DataStoreActionType<Company>
) => ({
  async loadUserSession(companyHash?: string): Promise<AuthStoreType> {
    authActions.setLoading();
    companiesActions.setLoading();
    const { data: user } = await deps.axios.get<User>("me");
    const company = user.companies.find((c) => c.hash === companyHash);
    if (companyHash && !company) {
      throw new PageNotFoundError("Company not found");
    }

    companiesActions.setLoaded({
      data: user.companies,
      pagination: {
        page: 1,
        perPage: user.companies.length,
        total: user.companies.length,
      },
    });
    if (company) {
      const auth = { authorized: true, user, company } as const;
      authActions.setLoaded(auth);
      return auth;
    } else {
      const auth = { authorized: true, user } as const;
      authActions.setLoaded(auth);
      return auth;
    }
  },

  async updateUserSettings(
    user: User,
    settings: Partial<UserSettingsType>
  ): Promise<void> {
    const { data } = await deps.axios.patch<
      User,
      AxiosResponse<User>,
      Partial<User>
    >(`users/${user.id}`, {
      settings: Object.assign({}, user.settings, settings),
    });
    authActions.setPartial({ user: data });
  },
});
