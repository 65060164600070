import { getTransactionType } from "@castiero/web/model/transactional";
import { FormChildrenProp, FormValues } from "@castiero/web/schemas/common";
import {
  Transaction,
  TransactionSchema,
  fromBudget,
} from "@castiero/modules/schema/Transaction";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material";
import moment from "moment";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MuiCurrencyField } from "../fields/MuiCurrencyField";
import { MuiField } from "../fields/MuiField";
import { Col, Row } from "../grid";
import { defaults } from "lodash";
import useAppStore from "@castiero/web/hooks/useAppStore";
import { Dropdown } from "../fields/Dropdown";
import { useApiService } from "@castiero/web/api/apiService";
import { useBudgetForm } from "@castiero/web/context/forms/budget";
import { useAccountForm } from "@castiero/web/context/forms/account";
import events from "@castiero/web/common/events";
import { Account } from "@castiero/web/schemas/Account";
import { useTagForm } from "@castiero/web/context/forms/tag";
import { Tag } from "@castiero/web/schemas/Tag";
import { FocusTrap } from "@mui/base";
import { ModalBox } from "../styled/modal";
import ModalHeader from "../MUI/modals/Header";
import useTrxAccountOptions from "../../hooks/forms/useTrxAccountOptions";

export type TransactionTypeType = {
  type: "income" | "transference" | "expense" | "loan";
};

export type TransactionFormType = FormValues<Transaction & TransactionTypeType>;

export const initTransaction: TransactionFormType = {
  id: "",
  type: "",
  description: "",
  value: "",
  status: "closed",
  due_date: moment().format("yyyy-MM-DD"),
  close_date: moment().format("yyyy-MM-DD"),
  source_account_id: "",
  target_account_id: "",
  company_id: "",
  budget_id: "",
  tag_ids: [],
};
export const TransactionForm: React.FC<
  FormChildrenProp<Transaction & TransactionTypeType>
> = ({ onSubmit, children, defaults: externDefaults, state, setState }) => {
  const api = useApiService();
  const defaultValues = defaults(externDefaults, initTransaction);
  const budgetForm = useBudgetForm();
  const accountForm = useAccountForm();
  const tagForm = useTagForm();
  const [sourceAccount, setSourceAccount] = React.useState<Account>();

  const methods = useForm<TransactionFormType>({
    resolver: yupResolver(TransactionSchema),
    defaultValues,
    reValidateMode: "onChange",
    mode: "onSubmit",
  });

  const {
    id: trx_id,
    budget_id,
    type,
    tag_ids,
    due_date,
    source_account_id,
    target_account_id,
  } = methods.watch();

  const { formState } = methods;
  const { t } = useTranslation();

  const tags = useAppStore("tags");
  const accounts = useAppStore("accounts");
  const budgets = useAppStore("budgets");

  const budgetOptions = trx_id
    ? budgets.options.filter((b) => b.target_account_id == target_account_id)
    : budgets.options;

  const { source, target } = useTrxAccountOptions(source_account_id);

  const isEdit = !!trx_id;
  const { query } = useRouter();

  React.useEffect(() => {
    if (!query.budget_id || Array.isArray(query.budget_id)) return;
    methods.setValue("budget_id" as const, Number(query.budget_id), {
      shouldDirty: true,
      shouldTouch: true,
    });
    setState?.(true);
  }, [query]);

  React.useEffect(() => {
    if (!!trx_id || !budget_id || !formState.dirtyFields.budget_id) return;
    api.budgets.getItem(budget_id, { w: ["tags", "source"] }).then((budget) => {
      methods.reset({
        ...fromBudget(budget),
        type: getTransactionType(budget),
      });
    });
  }, [budget_id, formState.dirtyFields.budget_id]);

  React.useEffect(() => {
    let mounted = true;
    if (!source_account_id) return;
    api.accounts.getItem(source_account_id).then((account) => {
      if (mounted) setSourceAccount(account);
    });
    return () => {
      mounted = false;
    };
  }, [source_account_id, accounts.options]);

  React.useEffect(() => {
    if (!sourceAccount) return;
    if (sourceAccount.type === "credit") {
      methods.setValue(
        "close_date" as const,
        moment().set("date", sourceAccount.billing_day).format("yyyy-MM-DD")
      );
    } else {
      methods.setValue("close_date" as const, due_date);
    }
  }, [sourceAccount, due_date]);

  const onSourceAccount = (account: Account) =>
    methods.setValue("source_account_id" as const, account.id, {
      shouldDirty: true,
      shouldTouch: true,
    });

  const onTargetAccount = (account: Account) =>
    methods.setValue("target_account_id" as const, account.id, {
      shouldDirty: true,
      shouldTouch: true,
    });

  const onTag = (tag: Tag) => {
    if (Array.isArray(tag_ids)) {
      methods.setValue("tag_ids" as const, [...tag_ids, String(tag.id)], {
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      methods.setValue("tag_ids" as const, [String(tag.id)], {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  return (
    <FocusTrap disableAutoFocus open>
      <Modal
        open={!!state}
        onClose={() => setState?.(false)}
        disableEnforceFocus
      >
        <ModalBox>
          <ModalHeader
            title={isEdit ? t`edit_transaction` : t`create_new_transaction`}
            onClose={() => setState?.(false)}
          />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Row>
                <Col md={12}>
                  <Dropdown
                    id="transaction-budget-form"
                    actionForm={(txt) => {
                      budgetForm.toggle(true, { description: txt });
                      events.once(events.BUDGET_CREATED, (budget) =>
                        methods.setValue("budget_id" as const, budget.id, {
                          shouldDirty: true,
                          shouldTouch: true,
                        })
                      );
                    }}
                    label={t("budget")}
                    options={budgetOptions}
                    name="budget_id"
                    error={formState.errors.budget_id?.message}
                  />
                </Col>
              </Row>
              <Row id={t(`transaction-form-${type}`)}>
                <Col md={6}>
                  <Dropdown
                    id="transaction-source_account"
                    actionForm={(txt) => {
                      accountForm.toggle(true, { description: txt });
                      events.off(events.ACCOUNT_CREATED, onTargetAccount);
                      events.once(events.ACCOUNT_CREATED, onSourceAccount);
                    }}
                    label={t("source_account")}
                    options={source}
                    name="source_account_id"
                    disabled={type === "income"}
                    error={formState.errors.source_account_id?.message}
                  />
                </Col>
                <Col md={6}>
                  <Dropdown
                    id="transaction-target_account"
                    actionForm={(txt) => {
                      accountForm.toggle(true, { description: txt });
                      events.off(events.ACCOUNT_CREATED, onSourceAccount);
                      events.once(events.ACCOUNT_CREATED, onTargetAccount);
                    }}
                    label={t("target_account")}
                    options={target}
                    name="target_account_id"
                    disabled={!!budget_id}
                    error={formState.errors.target_account_id?.message}
                  />
                </Col>
                <Col md={6}>
                  <MuiField
                    label={t("description")}
                    placeholder={t(`description_${type}`)}
                    name="description"
                    id="transaction-description"
                    error={formState.errors.description?.message}
                  />
                </Col>
                <Col md={6}>
                  <MuiCurrencyField
                    label={t("value")}
                    placeholder={t(`value_${type}`)}
                    name="value"
                    id="transaction-value"
                    error={formState.errors.value?.message}
                  />
                </Col>
                <Col md={6}>
                  <MuiField
                    label={t("date")}
                    id="transaction-due_date"
                    type="date"
                    error={formState.errors.close_date?.message}
                    name="due_date"
                  />
                </Col>
                <Col md={6}>
                  <Dropdown
                    id="transaction-tag_ids"
                    actionForm={(txt) => {
                      tagForm.toggle(true, { name: txt });
                      events.once(events.TAG_CREATED, onTag);
                    }}
                    label={t("tags")}
                    options={tags.options}
                    name="tag_ids"
                    multiple
                    error={formState.errors.tag_ids?.message}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {children(
                    () => methods.reset(defaultValues),
                    !trx_id,
                    methods.formState
                  )}
                </Col>
              </Row>
            </form>
          </FormProvider>
        </ModalBox>
      </Modal>
    </FocusTrap>
  );
};
