import { User } from "@castiero/web/schemas/User";
import { forwardEvents } from "./eventEmitter";

const USER_CREATED = "user_created";
const USER_UPDATED = "user_updated";
const USER_DELETED = "user_deleted";
const USER_CONTEXT_CHANGED = "user_context_changed";

export type UserEventsType = {
  [USER_CREATED]: User;
  [USER_UPDATED]: User;
  [USER_DELETED]: User;
  [USER_CONTEXT_CHANGED]: undefined;
};

forwardEvents([USER_CREATED, USER_UPDATED, USER_DELETED], USER_CONTEXT_CHANGED);

export default {
  USER_CREATED,
  USER_UPDATED,
  USER_DELETED,
  USER_CONTEXT_CHANGED,
} as const;
