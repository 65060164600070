import { Waitlist } from "@castiero/web/schemas/Waitlist";
import { ApiServiceDeps } from "./types";
import { DataStoreActionType } from "@castiero/modules/redux/createDataSlice";

export const access =
  (deps: ApiServiceDeps, actions: DataStoreActionType<Waitlist>) =>
  (id: Waitlist["id"]) =>
    deps.axios
      .post<Waitlist>(`/waitlist/${id}/access`)
      .then(({ data }) => actions.updateItem(data));
