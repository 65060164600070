import * as yup from "yup";
import { FormValues, Shape, UserCompany } from "./common";
import { User } from "./User";
import { CompanySettingsType } from "@castiero/modules/schema/Company";

export interface Company<T extends boolean = true> {
  id: string;
  name: string;
  email: string;
  settings?: CompanySettingsType;
  is_individual: boolean;
  readonly created_at: Date;
  readonly deleted_at: null;
  readonly updated_at: Date;
  readonly hash: string;
  readonly pivot: T extends true ? UserCompany : never;
  readonly users?: User<true>[];
}

export const CompanySchema = yup.object<Shape<FormValues<Company>>>().shape({
  id: yup.string(),
  name: yup.string().required(),
  email: yup.string().email().required(),
  is_individual: yup.boolean().default(false),
});

export const CompanyCast = (data: unknown) =>
  CompanySchema.cast(data) as Company;
