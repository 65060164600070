export const chartColors = [
  "#9BF6FF",
  "#CAFFBF",
  "#FDFFB6",
  "#FFD6A5",
  "#FFADAD",
  "#F6BD60",
  "#F7EDE2",
  "#F5CAC3",
  "#84A59D",
  "#F28482",
  "#C9CBA3",
  "#FFE1A8",
  "#E26D5C",
  "#723D46",
  "#472D30",
  "#797D62",
  "#9B9B7A",
  "#BAA587",
  "#D9AE94",
  "#F1DCA7",
  "#C32F27",
  "#D8572A",
  "#DB7C26",
  "#F7B538",
  "#780116",
  "#FFE6A7",
  "#99582A",
  "#432818",
  "#BB9457",
  "#6F1D1B",
  "#FFC6FF",
  "#BDB2FF",
  "#A0C4FF",
  "#BB4AE3",
  "#3A475C",
];
