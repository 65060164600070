import {
  Container,
  Pagination,
  TablePagination,
  useMediaQuery,
} from "@mui/material";
import { screens } from "@castiero/web/common/theme";
import * as React from "react";

interface TablePaginationProps {
  total: number;
  perPage: number;
  page: number;

  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MuiTablePagination = (props: TablePaginationProps) => {
  const { handleChangePage, handleChangeRowsPerPage, page, perPage, total } =
    props;

  const mediumDevice = useMediaQuery(`(min-width: ${screens.tablet})`);

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        padding: 0,
        [`@media (min-width: ${screens.tablet})`]: {
          flexDirection: "row",
          maxWidth: "100%",
        },
      }}
    >
      <TablePagination
        padding="none"
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          "& .MuiToolbar-root": {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 100]}
        count={total}
        rowsPerPage={perPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={() => null}
      />
      <Pagination
        page={page + 1}
        count={Math.ceil(total / perPage)}
        onChange={handleChangePage}
        showFirstButton
        showLastButton
        defaultPage={1}
        siblingCount={0}
        boundaryCount={1}
        size={!mediumDevice ? "small" : "medium"}
      />
    </Container>
  );
};
