import { JsQuery } from "@castiero/modules/types/JsQuery";

export function countQuery(js_query: JsQuery): JsQuery {
  return {
    ...js_query,
    count: true,
    relations: undefined,
    order_by: undefined,
    limit: undefined,
    offset: undefined,
  };
}
