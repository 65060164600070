import * as yup from "yup";
import { Account } from "./Account";
import { Budget } from "./Budget";
import { Company } from "./Company";
import { Tag } from "./Tag";
import { numberSchema } from "./common";

// Types
import { FormValues } from "../types/FormValues";
import { Transactable } from "../types/Transactable";
import { Shape } from "../types/yup";
import dayjs from "dayjs";
import { UserStamps } from "../types/UserStamps";

export type TransactionStatus = "budgeted" | "debited" | "closed";

export interface Transaction extends Transactable {
  id: number;
  description: string;
  value: number;
  status: TransactionStatus;
  due_date?: string;
  close_date?: string;
  source_account_id?: Account["id"];
  target_account_id: Account["id"];
  company_id: Company["id"];
  budget_id?: Budget["id"];
  tag_ids?: string[];
  tags?: Tag[];
  customer_id?: number;
  operation_id?: string;
}

export const TransactionSchema = yup
  .object<Shape<FormValues<Transaction>>>()
  .shape({
    id: numberSchema,
    budget_id: numberSchema,
    description: yup.string().required(),
    value: numberSchema.required(),
    status: yup.string().required(),
    due_date: yup.date().required(),
    close_date: yup.date().when("status", {
      is: "closed",
      then: yup.date().required(),
    }),
    source_account_id: numberSchema,
    target_account_id: numberSchema.required(),
    tag_ids: yup.array(yup.string()).nullable(),
  });

export const fromBudget = ({
  id,
  ...budget
}: Budget): Omit<Transaction, "id"> => ({
  ...budget,
  source_account_id: budget.source_account_id ?? 0,
  budget_id: id,
  status: "budgeted",
  description: budget.description + " " + dayjs().format("MMM YYYY"),
  due_date: dayjs().format("YYYY-MM-DD"),
  tag_ids: budget.tags?.map(({ id }) => String(id)) ?? [],
});

export const TransactionCast = (data: unknown) =>
  TransactionSchema.cast(data, {
    stripUnknown: true,
  });

export const isTransaction = (data: unknown): data is Transaction =>
  TransactionSchema.isValidSync(data);
