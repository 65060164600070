import { UserCompany } from "../schemas/common";
import { TransactionStatus } from "../schemas/Transaction";
import { BudgetFrequency } from "../schemas/Budget";
import { AccountType } from "../schemas/Account";

export const accountTypeOptions: Record<AccountType, string> = {
  debit: "debit",
  credit: "credit",
  cost: "cost",
  receivable: "receivable",
};

export const budgetsFrequencyOptions: Record<BudgetFrequency, string> = {
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly",
  bimonthly: "bimonthly",
  quarterly: "quarterly",
  biannual: "biannual",
  annual: "annual",
};

export const transactionsStatus: Record<TransactionStatus, string> = {
  budgeted: "budgeted",
  debited: "debited",
  closed: "closed",
};

export const roles: Record<UserCompany["role"], string> = {
  owner: "owner",
  admin: "admin",
  manager: "manager",
};
