import { useApiService } from "@castiero/web/api/apiService";
import { colors, theme } from "@castiero/web/common/theme";
import { toastSaveParams } from "@castiero/web/common/toast";
import { ButtonPrimary } from "@castiero/web/components/buttons";
import { useTranslation } from "next-i18next";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Row, Col } from "react-grid-system";
import { toast } from "react-toastify";
import { FormContext } from "../common/types";
import {
  CompanyForm,
  CompanyFormType,
  initCompany,
} from "@castiero/web/components/forms/CompanyForm";
import { Company, CompanyCast } from "@castiero/web/schemas/Company";

const CompanyFormContext = createContext<FormContext<Company, CompanyFormType>>(
  undefined as never
);

export const CompanyFormProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState<CompanyFormType>();
  const toggle = useCallback(
    (_active: boolean, Company?: Partial<CompanyFormType>) => {
      setActive(_active != active);
      setValues({ ...initCompany, ...Company });
    },
    [active]
  );

  const { t } = useTranslation();
  const { companies: companyAPI } = useApiService();

  const state = useMemo(
    () => ({ active, values, toggle }),
    [active, values, toggle]
  );

  const onSubmit = async (newCompany: CompanyFormType) => {
    await toast.promise(
      companyAPI.save(CompanyCast(newCompany)),
      toastSaveParams("company", t)
    );
    setActive(false);
  };

  return (
    <CompanyFormContext.Provider value={state}>
      {children}
      {active ? (
        <CompanyForm
          defaults={values}
          state={true}
          setState={setActive}
          onSubmit={onSubmit}
        >
          {(reset, create) => (
            <Row>
              <Col>
                <ButtonPrimary
                  type="button"
                  onClick={() => {
                    reset();
                    setActive(false);
                  }}
                  style={{ backgroundColor: theme.palette.error.main }}
                >
                  {t("cancel")}
                </ButtonPrimary>
              </Col>
              <Col>
                <ButtonPrimary
                  type="submit"
                  style={{ backgroundColor: colors.success }}
                >
                  {create ? t("create") : t("save")}
                </ButtonPrimary>
              </Col>
            </Row>
          )}
        </CompanyForm>
      ) : null}
    </CompanyFormContext.Provider>
  );
};

export const useCompanyForm = () => {
  const context = useContext(CompanyFormContext);
  if (!context) {
    throw new Error("useCompanyForm must be used within a TagFormProvider");
  }
  return context;
};
