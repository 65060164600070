import { FormChildrenProp, FormValues } from "@castiero/web/schemas/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Modal } from "@mui/material";
import { defaults } from "lodash";
import { useTranslation } from "next-i18next";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MuiField } from "../fields/MuiField";
import { Col } from "../grid";
import { ImportModel, ImportSchema } from "@castiero/web/schemas/ImportModel";
import { FocusTrap } from "@mui/base";
import { ModalBox } from "../styled/modal";
import ModalHeader from "../MUI/modals/Header";

export type ModelsFormType = FormValues<ImportModel>;

export const initModel: ModelsFormType = {
  id: "",
  name: "",
  notes: "",
  model_data: "",
  company_id: "",
};

export const ImportModelForm: React.FC<FormChildrenProp<ImportModel>> = ({
  onSubmit,
  children,
  defaults: defaultValues,
  state,
  setState,
}) => {
  const localDefaultValues = defaults(defaultValues, initModel);
  const methods = useForm<FormValues<ImportModel & { type: string }>>({
    resolver: yupResolver(ImportSchema),
    defaultValues: localDefaultValues,
  });
  const [id] = methods.watch(["id"] as const);
  const { formState, reset, control } = methods;
  const { t } = useTranslation();

  return (
    <FocusTrap disableAutoFocus open>
      <Modal
        open={!!state}
        onClose={() => setState?.(false)}
        disableEnforceFocus
      >
        <ModalBox>
          <ModalHeader
            title={defaultValues?.id ? t("edit_model") : t("create_model")}
            onClose={() => setState?.(false)}
          />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={12}>
                  <MuiField
                    control={control}
                    name="name"
                    id="importing-create-model-input"
                    label={t("model")}
                    error={formState.errors.name?.message}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="importing-description-model-input"
                >
                  <MuiField
                    control={control}
                    name="notes"
                    label={t("note")}
                    rows={4}
                    multiline
                  />
                </Grid>
                <Col xs={12}>
                  {children(
                    () => reset(localDefaultValues),
                    !id,
                    methods.formState
                  )}
                </Col>
              </Grid>
            </form>
          </FormProvider>
        </ModalBox>
      </Modal>
    </FocusTrap>
  );
};
