import {
  CaseReducer,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ObjectStoreType } from "../types/store";

const getInitialState = <T>(init: T): ObjectStoreType<T> => ({
  status: "idle",
  data: init,
  errorMessage: "",
});

const setLoading =
  <T>(): CaseReducer<ObjectStoreType<T>> =>
  (state) => {
    state.status = "loading";
    state.errorMessage = "";
  };

const setPartial =
  <T>(): CaseReducer<ObjectStoreType<T>, PayloadAction<Partial<T>>> =>
  (state, { payload }) => ({
    ...state,
    data: { ...state.data, ...payload },
  });

const setLoaded =
  <T>(): CaseReducer<ObjectStoreType<T>, PayloadAction<T>> =>
  (state, { payload }) => {
    state.status = "loaded";
    state.data = payload as Draft<T>;
    state.errorMessage = "";
  };

const setError =
  <T>(): CaseReducer<ObjectStoreType<T>, PayloadAction<{ message: string }>> =>
  (state, { payload }) => {
    state.status = "error";
    state.errorMessage = payload.message;
  };

export const createObjectStore = <F extends string, T>(name: F, init: T) =>
  createSlice({
    name,
    initialState: getInitialState<T>(init),
    reducers: {
      setLoading: setLoading<T>(),
      setLoaded: setLoaded<T>(),
      setError: setError<T>(),
      setPartial: setPartial<T>(),
    },
    extraReducers: {
      HYDRATE: (state, action) => {
        return action.payload[name] ?? state;
      },
    },
  });

export type ObjectStoreActionType<T> = ReturnType<
  typeof createObjectStore<string, T>
>["actions"];
