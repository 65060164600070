import { object, string } from "yup";
import { numberSchema } from "./common";
import { Account } from "./Account";
import { Transaction } from "./Transaction";
import { Shape } from "../types/yup";
import { FormValues } from "../types/FormValues";
import { UserStamps } from "../types/UserStamps";
import { CustomerContact } from "./CustomerContact";

export interface Customer extends UserStamps {
  id: number;
  name: string;
  phone: string;
  notes: string;
  account_id: Account["id"];
  readonly contacts_count: number;
  readonly contacts: Array<CustomerContact>;
  readonly balance: number;
  readonly account: Account;
  readonly transactions: Array<Transaction>;
}

export const CustomerSchema = object<Shape<FormValues<Customer>>>().shape({
  id: numberSchema,
  name: string().required(),
  phone: string(),
  notes: string(),
  account_id: numberSchema.required(),
});

export const CustomerCast = (data: unknown) =>
  CustomerSchema.cast(data, { stripUnknown: true }) as Customer;

export const isCustomer = (data: unknown): data is Customer =>
  CustomerSchema.isValidSync(data);
