import { FormValues, numberSchema, Shape } from "./common";
import * as yup from "yup";
import { Company } from "./Company";

export interface ImportModel {
  id: string;
  name: string;
  notes: string;
  model_data: string;
  company_id: string;
  readonly company: Company;
}

export const ImportSchema = yup.object<Shape<FormValues<ImportModel>>>().shape({
  id: numberSchema,
  name: yup.string().required(),
  notes: yup.string().required(),
});

export const ImportCast = (data: unknown): ImportModel => {
  if (!isImport(data)) throw new Error("Data is not a import");
  return ImportSchema.cast(data) as unknown as ImportModel;
};

export const isImport = (data: unknown): data is ImportModel =>
  ImportSchema.isValidSync(data);
