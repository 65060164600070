import { createDataSlice } from "@castiero/modules/redux/createDataSlice";
import { createObjectStore } from "@castiero/modules/redux/createObjectStore";
import { Customer } from "@castiero/modules/schema/Customer";
import { CustomerContact } from "@castiero/modules/schema/CustomerContact";
import { Cashflow } from "@castiero/modules/types/Cashflow";
import { Account } from "@castiero/web/schemas/Account";
import { Budget } from "@castiero/web/schemas/Budget";
import { Company } from "@castiero/web/schemas/Company";
import { ImportModel } from "@castiero/web/schemas/ImportModel";
import { Tag } from "@castiero/web/schemas/Tag";
import { Transaction } from "@castiero/web/schemas/Transaction";
import { User } from "@castiero/web/schemas/User";
import { Waitlist } from "@castiero/web/schemas/Waitlist";
import {
  ActionCreatorsMapObject,
  AnyAction,
  applyMiddleware,
  bindActionCreators,
  combineReducers,
  configureStore,
  Dispatch,
} from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import { DefaultCostCenter } from "../schemas/DefaultCostCenter";
import { AuthStoreType } from "./types";
import { hydrateMiddleware } from "./hydrateMiddleware";

const defaultCashflow = {
  expenses: 0,
  incoming: 0,
  expenses_batch: [],
  incoming_batch: [],
};
const auth = createObjectStore<"auth", AuthStoreType>("auth", {
  authorized: false,
});
const cashflow = createObjectStore<"cashflow", Cashflow>(
  "cashflow",
  defaultCashflow
);
const accounts = createDataSlice<"accounts", Account>("accounts");
const budgets = createDataSlice<"budgets", Budget>("budgets");
const companies = createDataSlice<"companies", Company>("companies");
const customers = createDataSlice<"customers", Customer>("customers");
const customer_contacts = createDataSlice<"customer_contacts", CustomerContact>(
  "customer_contacts"
);
const transactions = createDataSlice<"transactions", Transaction>(
  "transactions"
);
const users = createDataSlice<"users", User<true>>("users");
const waitlist = createDataSlice<"waitlist", Waitlist>("waitlist");
const tags = createDataSlice<"tags", Tag>("tags");
const import_models = createDataSlice<"import_models", ImportModel>(
  "import_models"
);
const defaultCostCenter = createDataSlice<
  "defaultCostCenter",
  DefaultCostCenter
>("defaultCostCenter");

const makeStore = () =>
  configureStore({
    reducer: combineReducers({
      [auth.name]: auth.reducer,
      [cashflow.name]: cashflow.reducer,
      [accounts.name]: accounts.reducer,
      [budgets.name]: budgets.reducer,
      [companies.name]: companies.reducer,
      [customers.name]: customers.reducer,
      [customer_contacts.name]: customer_contacts.reducer,
      [transactions.name]: transactions.reducer,
      [users.name]: users.reducer,
      [waitlist.name]: waitlist.reducer,
      [tags.name]: tags.reducer,
      [import_models.name]: import_models.reducer,
      [defaultCostCenter.name]: defaultCostCenter.reducer,
    }),
    devTools: process.env.NODE_ENV !== "production",
    enhancers: [applyMiddleware(hydrateMiddleware)],
  });

export type AppStoreType = ReturnType<typeof makeStore>;
export type AppStateType = ReturnType<AppStoreType["getState"]>;

const actionCreatorsBinder =
  <T extends ActionCreatorsMapObject>(actions: T) =>
  (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(actions, dispatch);

export const actions = {
  auth: actionCreatorsBinder(auth.actions),
  cashflow: actionCreatorsBinder(cashflow.actions),
  accounts: actionCreatorsBinder(accounts.actions),
  companies: actionCreatorsBinder(companies.actions),
  customers: actionCreatorsBinder(customers.actions),
  customer_contacts: actionCreatorsBinder(customer_contacts.actions),
  budgets: actionCreatorsBinder(budgets.actions),
  transactions: actionCreatorsBinder(transactions.actions),
  users: actionCreatorsBinder(users.actions),
  waitlist: actionCreatorsBinder(waitlist.actions),
  tags: actionCreatorsBinder(tags.actions),
  import_models: actionCreatorsBinder(import_models.actions),
  defaultCostCenter: actionCreatorsBinder(defaultCostCenter.actions),
};

export const selectors = {
  auth: (state: AppStateType) => state[auth.name],
  cashflow: (state: AppStateType) => state[cashflow.name],
  accounts: (state: AppStateType) => state[accounts.name],
  budgets: (state: AppStateType) => state[budgets.name],
  companies: (state: AppStateType) => state[companies.name],
  customers: (state: AppStateType) => state[customers.name],
  customer_contacts: (state: AppStateType) => state[customer_contacts.name],
  transactions: (state: AppStateType) => state[transactions.name],
  users: (state: AppStateType) => state[users.name],
  waitlist: (state: AppStateType) => state[waitlist.name],
  tags: (state: AppStateType) => state[tags.name],
  import_models: (state: AppStateType) => state[import_models.name],
  defaultCostCenter: (state: AppStateType) => state[defaultCostCenter.name],
};

export default createWrapper<AppStoreType>(makeStore);
