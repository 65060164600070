import { FormChildrenProp, FormValues } from "@castiero/web/schemas/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material";
import { defaults } from "lodash";
import { useTranslation } from "next-i18next";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MuiField } from "../fields/MuiField";
import { Col, Row } from "../grid";
import { Tag, TagSchema } from "@castiero/web/schemas/Tag";
import { chartColors } from "@castiero/web/common/chartColors";
import { FocusTrap } from "@mui/base";
import { ModalBox } from "../styled/modal";
import ModalHeader from "../MUI/modals/Header";

export type TagFormType = FormValues<Tag>;

export const initTag: TagFormType = {
  id: "",
  name: "",
  color: "",
  company_id: "",
};

export const TagForm: React.FC<FormChildrenProp<Tag>> = ({
  onSubmit,
  children,
  defaults: defaultValues,
  state,
  setState,
}) => {
  const randomIndex = Math.floor(Math.random() * chartColors.length);
  const randomColor = chartColors[randomIndex];
  const localDefaultValues = defaults(defaultValues, initTag, {
    color: randomColor,
  });
  const methods = useForm<FormValues<Tag & { type: string }>>({
    resolver: yupResolver(TagSchema),
    defaultValues: localDefaultValues,
  });
  const [id] = methods.watch(["id", "type"] as const);
  const { formState, reset } = methods;
  const { t } = useTranslation();

  //const defaultColor = defaultValues?.id ? defaultValues.color : randomColor;

  return (
    <FocusTrap disableAutoFocus open>
      <Modal
        open={!!state}
        onClose={() => setState?.(false)}
        disableEnforceFocus
      >
        <ModalBox>
          <ModalHeader
            title={defaultValues?.id ? t("edit_tag") : t("create_new_tag")}
            onClose={() => setState?.(false)}
          />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Row>
                <Col sm={6}>
                  <MuiField
                    label={t`name`}
                    name="name"
                    error={formState.errors.name?.message}
                  />
                </Col>
                <Col sm={6}>
                  <MuiField
                    label={t`color`}
                    id="colors"
                    type="color"
                    name="color"
                    style={{ width: "150px" }}
                    inputProps={{ list: "colorListDefined" }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <datalist id="colorListDefined">
                    {chartColors.map((color) => (
                      <option
                        key={color}
                        value={color}
                        style={{ borderRadius: "50%" }}
                      />
                    ))}
                  </datalist>
                </Col>

                <Col xs={12}>
                  {children(
                    () => reset(localDefaultValues),
                    !id,
                    methods.formState
                  )}
                </Col>
              </Row>
            </form>
          </FormProvider>
        </ModalBox>
      </Modal>
    </FocusTrap>
  );
};
