import { Customer } from "@castiero/modules/schema/Customer";
import { forwardEvents } from "./eventEmitter";

const CUSTOMER_CREATED = "customer_created";
const CUSTOMER_UPDATED = "customer_updated";
const CUSTOMER_DELETED = "customer_deleted";
const CUSTOMER_CONTEXT_CHANGED = "customer_context_changed";

export type CustomerEventsType = {
  [CUSTOMER_CREATED]: Customer;
  [CUSTOMER_UPDATED]: Customer;
  [CUSTOMER_DELETED]: Customer;
  [CUSTOMER_CONTEXT_CHANGED]: undefined;
};

forwardEvents(
  [CUSTOMER_CREATED, CUSTOMER_UPDATED, CUSTOMER_DELETED],
  CUSTOMER_CONTEXT_CHANGED
);

export default {
  CUSTOMER_CREATED,
  CUSTOMER_UPDATED,
  CUSTOMER_DELETED,
  CUSTOMER_CONTEXT_CHANGED,
} as const;
