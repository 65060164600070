import { Company } from "@castiero/web/schemas/Company";
import { forwardEvents } from "./eventEmitter";

const COMPANY_CREATED = "company_created";
const COMPANY_UPDATED = "company_updated";
const COMPANY_DELETED = "company_deleted";
const COMPANY_CONTEXT_CHANGED = "company_context_changed";

export type CompanyEventsType = {
  [COMPANY_CREATED]: Company;
  [COMPANY_UPDATED]: Company;
  [COMPANY_DELETED]: Company;
  [COMPANY_CONTEXT_CHANGED]: undefined;
};

forwardEvents(
  [COMPANY_CREATED, COMPANY_UPDATED, COMPANY_DELETED],
  COMPANY_CONTEXT_CHANGED
);

export default {
  COMPANY_CREATED,
  COMPANY_UPDATED,
  COMPANY_DELETED,
  COMPANY_CONTEXT_CHANGED,
} as const;
