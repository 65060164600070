import { useApiService } from "@castiero/web/api/apiService";
import { colors, theme } from "@castiero/web/common/theme";
import { toastSaveParams } from "@castiero/web/common/toast";
import { ButtonPrimary } from "@castiero/web/components/buttons";
import { FormValues } from "@castiero/web/schemas/common";
import { useTranslation } from "next-i18next";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Row, Col } from "react-grid-system";
import { toast } from "react-toastify";
import { FormContext } from "../common/types";
import {
  CustomerForm,
  CustomerFormType,
  initCustomer,
} from "@castiero/web/components/forms/CustomerForm";
import { Customer, CustomerCast } from "@castiero/modules/schema/Customer";

const CustomerFormContext = createContext<
  FormContext<Customer, CustomerFormType>
>(undefined as never);

export const CustomerFormProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState<CustomerFormType>();
  const toggle = useCallback(
    (_active: boolean, Customer?: Partial<CustomerFormType>) => {
      setActive(_active != active);
      setValues({ ...initCustomer, ...Customer });
    },
    [active]
  );

  const { t } = useTranslation();
  const apiService = useApiService();

  const state = useMemo(
    () => ({ active, values, toggle }),
    [active, values, toggle]
  );

  const onSubmit = async (data: FormValues<CustomerFormType>) => {
    await toast.promise(
      apiService.customers.save(CustomerCast(data)),
      toastSaveParams("customer", t)
    );
    setActive(false);
  };

  return (
    <CustomerFormContext.Provider value={state}>
      {children}
      {active ? (
        <CustomerForm
          defaults={values}
          state={true}
          setState={setActive}
          onSubmit={onSubmit}
        >
          {(reset, create) => (
            <Row>
              <Col>
                <ButtonPrimary
                  type="button"
                  style={{ backgroundColor: theme.palette.error.main }}
                  onClick={() => {
                    reset();
                    setActive(false);
                  }}
                >
                  {t("cancel")}
                </ButtonPrimary>
              </Col>
              <Col>
                <ButtonPrimary
                  type="submit"
                  style={{ backgroundColor: colors.success }}
                >
                  {!create ? t("save") : t("create")}
                </ButtonPrimary>
              </Col>
            </Row>
          )}
        </CustomerForm>
      ) : null}
    </CustomerFormContext.Provider>
  );
};

export const useCustomerForm = () => {
  const context = useContext(CustomerFormContext);
  if (!context) {
    throw new Error(
      "useCustomerForm must be used within a CustomerFormProvider"
    );
  }
  return context;
};
