import { Company } from "./Company";
import { FormValues, Shape } from "./common";
import * as yup from "yup";

export interface Tag {
  id: number;
  color: string;
  name: string;
  company_id: Company["id"];
}

export const TagSchema = yup.object<Shape<FormValues<Tag>>>().shape({
  name: yup.string().required(),
  color: yup.string().required(),
});

export const TagCast = (data: unknown) => TagSchema.cast(data) as Tag;

export const isTag = (data: unknown): data is Tag =>
  TagSchema.isValidSync(data);
