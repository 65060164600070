import { useApiService } from "@castiero/web/api/apiService";
import { colors, theme } from "@castiero/web/common/theme";
import { toastSaveParams } from "@castiero/web/common/toast";
import { ButtonPrimary } from "@castiero/web/components/buttons";
import { FormValues } from "@castiero/web/schemas/common";
import { useTranslation } from "next-i18next";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Row, Col } from "react-grid-system";
import { toast } from "react-toastify";
import { FormContext } from "../common/types";
import { ImportCast, ImportModel } from "@castiero/web/schemas/ImportModel";
import {
  ImportModelForm,
  ModelsFormType,
  initModel,
} from "@castiero/web/components/forms/ImportModelForm";

const ImportModelFormContext = createContext<
  FormContext<ImportModel, ModelsFormType>
>(undefined as never);

export const ModelFormProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState<ModelsFormType>();
  const [isDataSaved, setIsDataSaved] = useState(false);

  const toggle = useCallback(
    (active: boolean, Model?: Partial<ModelsFormType>) => {
      setActive(active);
      setIsDataSaved(false);
      setValues({ ...initModel, ...Model });
    },
    [active]
  );

  const { t } = useTranslation();
  const apiService = useApiService();

  const state = useMemo(
    () => ({ active, values, toggle, isDataSaved }),
    [active, values, toggle, isDataSaved]
  );

  const onSubmit = async (data: FormValues<ModelsFormType>) => {
    await toast.promise(
      apiService.import_models.save(ImportCast(data)),
      toastSaveParams("models", t)
    );
    setActive(false);
    setIsDataSaved(true);
  };

  return (
    <ImportModelFormContext.Provider value={state}>
      {children}
      {active ? (
        <ImportModelForm
          defaults={values}
          state={true}
          setState={setActive}
          onSubmit={onSubmit}
        >
          {(reset, create) => (
            <Row>
              <Col>
                <ButtonPrimary
                  type="button"
                  onClick={() => {
                    reset();
                    setActive(false);
                  }}
                  style={{ backgroundColor: theme.palette.error.main }}
                >
                  {t("cancel")}
                </ButtonPrimary>
              </Col>
              <Col>
                <ButtonPrimary
                  id="importing-create-model-button"
                  type="submit"
                  style={{ backgroundColor: colors.success }}
                >
                  {!create ? t("save") : t("create")}
                </ButtonPrimary>
              </Col>
            </Row>
          )}
        </ImportModelForm>
      ) : null}
    </ImportModelFormContext.Provider>
  );
};

export const useImportModelForm = () => {
  const context = useContext(ImportModelFormContext);
  if (!context) {
    throw new Error("useModelForm must be used within a ModelFormProvider");
  }
  return context;
};
