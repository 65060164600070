import { Close } from "@mui/icons-material";
import { Stack, Typography, IconButton } from "@mui/material";
import { sizes } from "../../../common/theme";

export default function ModalHeader({
  title,
  onClose,
}: {
  title: string;
  onClose: () => void;
}) {
  return (
    <Stack
      flex={0}
      marginBottom={sizes.x4}
      direction="row"
      justifyContent="space-between"
    >
      <Typography sx={{ textTransform: "capitalize" }} variant="h2">
        {title}
      </Typography>
      <IconButton onClick={onClose}>
        <Close color="primary" fontSize="large" />
      </IconButton>
    </Stack>
  );
}
