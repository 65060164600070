import { theme } from "@castiero/web/common/theme";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";

export const MuiCheckBox = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    control?: Control<any>;
    error?: string;
    name: string;
    id: string;
  }
>(({ label, control, ...props }) => {
  return (
    <Controller
      control={control}
      name={props.name || ""}
      render={({ field: { onChange, value } }) => {
        return (
          <FormControl
            id={props.id}
            sx={{
              width: "100%",
              marginBlock: "10px",
              [theme.breakpoints.down("md")]: {
                marginBlockEnd: "20px",
                marginBlockStart: 0,
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  id={props.id}
                  defaultChecked
                  onChange={onChange}
                  checked={value}
                />
              }
              label={label}
            />
          </FormControl>
        );
      }}
    />
  );
});

MuiCheckBox.displayName = "MuiCheckBox";
