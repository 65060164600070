import { Account } from "@castiero/web/schemas/Account";
import { forwardEvents } from "./eventEmitter";

const ACCOUNT_CREATED = "account_created";
const ACCOUNT_UPDATED = "account_updated";
const ACCOUNT_DELETED = "account_deleted";
const ACCOUNT_CONTEXT_CHANGED = "account_context_changed";

export type AccountEventsType = {
  [ACCOUNT_CREATED]: Account;
  [ACCOUNT_UPDATED]: Account;
  [ACCOUNT_DELETED]: Account;
  [ACCOUNT_CONTEXT_CHANGED]: undefined;
};

forwardEvents(
  [ACCOUNT_CREATED, ACCOUNT_UPDATED, ACCOUNT_DELETED],
  ACCOUNT_CONTEXT_CHANGED
);

export default {
  ACCOUNT_CREATED,
  ACCOUNT_UPDATED,
  ACCOUNT_DELETED,
  ACCOUNT_CONTEXT_CHANGED,
} as const;
