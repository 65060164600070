import { createGlobalStyle } from "styled-components";
import * as theme from "@castiero/web/common/theme";

const GlobalStyle = createGlobalStyle`
html,body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: ${theme.sizes.x3};
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  vertical-align: top;
  //color: ${theme.colors.darkFont};
}
// hide recaptcha budge
.grecaptcha-badge {visibility: hidden;}
.react-datepicker-wrapper {width: 100%;}
`;

export default GlobalStyle;
