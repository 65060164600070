import EventEmitter from "events";
import { EventKeys } from ".";

export const eventEmitter = new EventEmitter();
const forwardEvent = (eventKey: EventKeys, eventKey2: EventKeys) =>
  eventEmitter.on(eventKey, (payload) => eventEmitter.emit(eventKey2, payload));
// Event forwarding

export const forwardEvents = (events: EventKeys[], forward: EventKeys) =>
  events.forEach((eventKey) => forwardEvent(eventKey, forward));
