import { BaseTextFieldProps, MenuItem, TextField } from "@mui/material";
import { useTranslation } from "next-i18next";
import React from "react";
import { Control, Controller } from "react-hook-form";

export type SelectOptionType = {
  label: string;
  value: string;
};

export type MuiSelectOptions =
  | Record<string, string>
  | string[]
  | SelectOptionType[];

const parseOptions = (options: MuiSelectOptions): SelectOptionType[] => {
  if (Array.isArray(options)) {
    return options.map((o) =>
      typeof o === "string" ? { label: o, value: o } : o
    );
  }
  return Object.entries(options).map(([label, value]) => ({
    label,
    value,
  }));
};

export const MuiSelectField = React.forwardRef<
  HTMLSelectElement,
  {
    label?: string;
    options: MuiSelectOptions;
    error?: string;
    control?: Control<any>;
    noPadding?: boolean;
  } & Omit<BaseTextFieldProps, "error">
>(({ control, error, options, label = "", noPadding, ...props }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={props.name || ""}
      render={({ field: { onChange, value = "" } }) => {
        return (
          <TextField
            variant="outlined"
            onChange={onChange}
            select
            label={label}
            sx={{ width: "100%", marginBottom: noPadding ? "0" : "20px" }}
            helperText={error}
            error={!!error}
            value={value}
            {...props}
          >
            {!props.required ? <MenuItem value={""}></MenuItem> : null}
            {parseOptions(options).map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {t?.(label) ?? label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    />
  );
});

MuiSelectField.displayName = "MuiSelectField";
