import {
  CaseReducer,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";

import { Identifiable } from "../types/Identificable";
import { DataStoreType, DropdownOption } from "../types/store";
import { Pagination, defaultPagination } from "../types/Pagination";

const getInitialState = <T extends Identifiable>(
  init: T[]
): DataStoreType<T> => ({
  status: "idle",
  data: init,
  optStatus: "idle",
  options: [],
  pagination: defaultPagination,
  errorMessage: "",
});

const setLoading =
  <T extends Identifiable>(): CaseReducer<DataStoreType<T>> =>
  (state) => {
    state.status = "loading";
    state.errorMessage = "";
  };

const setLoaded =
  <T extends Identifiable>(): CaseReducer<
    DataStoreType<T>,
    PayloadAction<{ data: T[]; pagination: Pagination }>
  > =>
  (state, { payload }) => {
    state.status = "loaded";
    state.data = payload.data as Draft<T[]>;
    state.pagination = payload.pagination;
    state.errorMessage = "";
  };

const updateItem =
  <T extends Identifiable>(): CaseReducer<DataStoreType<T>, PayloadAction<T>> =>
  (state, { payload }) => {
    const index = state.data.findIndex((o) => o.id === payload.id);

    if (index !== -1) {
      state.data[index] = payload as Draft<T>;
    } else {
      state.data.push(payload as Draft<T>);
    }
  };

const deleteItem =
  <T extends Identifiable>(): CaseReducer<DataStoreType<T>, PayloadAction<T>> =>
  (state, { payload }) => {
    state.data = state.data.filter((o) => o.id !== payload.id);
  };

const setError =
  <T extends Identifiable>(): CaseReducer<
    DataStoreType<T>,
    PayloadAction<{ message: string }>
  > =>
  (state, { payload }) => {
    state.status = "error";
    state.errorMessage = payload.message;
  };

const setOptions =
  <T extends Identifiable>(): CaseReducer<
    DataStoreType<T>,
    PayloadAction<DropdownOption<T>[]>
  > =>
  (state, { payload }) => {
    state.optStatus = "loaded";
    state.options = payload as Draft<DropdownOption<T>[]>;
  };

export const createDataSlice = <N extends string, T extends Identifiable>(
  name: N,
  init: T[] = []
) =>
  createSlice({
    name,
    initialState: getInitialState<T>(init),
    reducers: {
      setLoading: setLoading<T>(),
      setLoaded: setLoaded<T>(),
      setError: setError<T>(),
      updateItem: updateItem<T>(),
      deleteItem: deleteItem<T>(),
      setOptions: setOptions<T>(),
    },
    extraReducers: {
      HYDRATE: (state, action) => {
        return action.payload[name] ?? state;
      },
    },
  });

export type DataStoreActionType<T extends Identifiable> = ReturnType<
  typeof createDataSlice<string, T>
>["actions"];
