import dayjs from "dayjs";
import { JsSearch } from "../types/JsQuery";

type BaseDate = Date | null;

export type DateRange = [BaseDate, BaseDate];

function stringifyDate(column: string, date: Date): string {
  if (["close_date", "due_date"].includes(column))
    return date.toISOString().substring(0, 10);

  return dayjs(date).startOf("d").toISOString();
}

export function rangeQuery(column: string, range?: DateRange): JsSearch {
  if (!range || !Array.isArray(range)) return {};
  const [start, end] = range;
  if (!start || !end) return {};
  return {
    [column]: `<>${stringifyDate(column, start)};${stringifyDate(column, end)}`,
  };
}

export function dateLimitSearch(column: string, range?: DateRange): JsSearch {
  if (!range) return {};
  const [start, end] = range;
  if (!end) {
    if (!start) return {};
    return { [column]: `>=${stringifyDate(column, start)}` };
  } else if (!start) {
    return { [column]: `<=${stringifyDate(column, end)}` };
  } else return rangeQuery(column, range);
}
