import { useApiService } from "@castiero/web/api/apiService";
import { colors, theme } from "@castiero/web/common/theme";
import { toastSaveParams } from "@castiero/web/common/toast";

import { ButtonPrimary } from "@castiero/web/components/buttons";
import {
  initTransaction,
  TransactionForm,
  TransactionFormType,
} from "@castiero/web/components/forms/TransactionForm";

import { FormValues } from "@castiero/web/schemas/common";
import {
  Transaction,
  TransactionCast,
} from "@castiero/web/schemas/Transaction";
import { useTranslation } from "next-i18next";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Row, Col } from "react-grid-system";
import { toast } from "react-toastify";
import { FormContext } from "../common/types";
import useAppStore from "@castiero/web/hooks/useAppStore";
import ev from "@castiero/web/common/events";
import moment from "moment";
import { matchAxiosApiException } from "@castiero/web/api/errors";

const getStatus = (data: FormValues<Transaction>) => {
  if (!data.source_account_id) data.close_date = data.due_date;
  if (moment(data.due_date).isAfter()) return "budgeted";
  if (moment(data.close_date).isAfter(data.due_date)) return "debited";
  return "closed";
};

const TransactionFormContext = createContext<
  FormContext<Transaction, TransactionFormType>
>(undefined as never);

export const TransactionFormProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState<TransactionFormType>();

  const toggle = useCallback(
    (_active: boolean, transaction?: Partial<TransactionFormType>) => {
      setActive(_active != active);
      setValues({ ...initTransaction, ...transaction });
    },
    [active]
  );
  const { t } = useTranslation();
  const apiService = useApiService();

  const isLoaded = [
    useAppStore("accounts").optStatus,
    useAppStore("budgets").optStatus,
    useAppStore("tags").optStatus,
  ].every((s) => s == "loaded");

  useEffect(() => {
    if (active) {
      apiService.budgets.getOptions();
      apiService.accounts.getOptions();
      apiService.tags.getOptions();

      const unSubBudgets = ev.on(
        ev.BUDGET_CONTEXT_CHANGED,
        apiService.budgets.getOptions
      );
      const unSubAccounts = ev.on(
        ev.ACCOUNT_CONTEXT_CHANGED,
        apiService.accounts.getOptions
      );
      const unSubTags = ev.on(
        ev.TAG_CONTEXT_CHANGED,
        apiService.tags.getOptions
      );
      return () => {
        unSubBudgets();
        unSubAccounts();
        unSubTags();
      };
    }

    return () => void 0;
  }, [active]);

  const onSubmit = async (data: TransactionFormType) => {
    data.status = getStatus(data);
    if (data.source_account_id === 0) {
      data = {
        ...data,
        source_account_id: undefined,
        type: "income",
      };
    }

    console.log(data);

    await toast
      .promise(apiService.transactions.save(TransactionCast(data)), {
        ...toastSaveParams("transaction", t),
        error: {
          render({ data }) {
            if (matchAxiosApiException("InsufficientFunds", data)) {
              return t("not_funds_enough");
            }
            return t(`transaction.saving_error`);
          },
        },
      })
      .then(() => setActive(false))
      .catch(() => setActive(true));
  };

  return (
    <TransactionFormContext.Provider value={{ active, values, toggle }}>
      {children}
      {active && isLoaded ? (
        <TransactionForm
          defaults={values}
          state={true}
          setState={setActive}
          onSubmit={onSubmit}
        >
          {(reset, create) => (
            <Row>
              <Col>
                <ButtonPrimary
                  id="transaction-cancel-button"
                  type="button"
                  onClick={() => {
                    reset();
                    setActive(false);
                  }}
                  style={{ backgroundColor: theme.palette.error.main }}
                >
                  {t("cancel")}
                </ButtonPrimary>
              </Col>
              <Col>
                <ButtonPrimary
                  id="transaction-create-button"
                  type="submit"
                  style={{ backgroundColor: colors.success }}
                >
                  {!create ? t("save") : t("create")}
                </ButtonPrimary>
              </Col>
            </Row>
          )}
        </TransactionForm>
      ) : null}
    </TransactionFormContext.Provider>
  );
};

export const useTransactionForm = () => {
  const context = useContext(TransactionFormContext);
  if (!context) {
    throw new Error(
      "useTransactionForm must be used within a TransactionFormProvider"
    );
  }
  return context;
};
