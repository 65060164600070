export type Pagination = {
  page: number;
  perPage: number;
  total: number;
};

export const defaultPagination: Pagination = {
  page: 0,
  perPage: 15,
  total: 0,
};
