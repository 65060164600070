import { Box } from "@mui/material";
import styled from "styled-components";
import { sizes, theme } from "../../common/theme";

export const ModalBox = styled(Box)<{ width?: number; height?: number }>`
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -24%);
  max-width: 100vw;
  width: ${({ width = 980 }) => width}px;
  ${({ height }) => (height ? `height: ${height}px` : "")};
  background-color: ${theme.palette.background.paper};
  box-shadow: 24px;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: ${sizes.x2};
  padding: ${sizes.x4} ${sizes.x6} ${sizes.x6};
  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    border-radius: 0;
    transform: unset;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    max-height: unset;
    border: unset;
  }
`;
