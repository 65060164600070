import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import wrapper from "@castiero/web/store";
import * as theme from "@castiero/web/common/theme";
import { Provider } from "react-redux";
import { FormProviders } from "@castiero/web/context/forms";
import GlobalStyle from "@castiero/web/components/styled/globalStyles";
import { ThemeProvider as MuiThemeProvider } from "@mui/system";
import Head from "next/head";

function MyApp({ Component, ...rest }: AppProps) {
  const {
    store,
    props: { pageProps },
  } = wrapper.useWrappedStore(rest);
  return (
    <>
      <Head>
        <title>Castiero constructor financiero</title>
        <link rel="icon" href="/favicon.png" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Head>
      <Provider store={store}>
        <MuiThemeProvider theme={theme.theme}>
          <ThemeProvider theme={theme}>
            <FormProviders>
              <GlobalStyle />
              <Component {...pageProps} />
              <ToastContainer />
            </FormProviders>
          </ThemeProvider>
        </MuiThemeProvider>
      </Provider>
    </>
  );
}

export default appWithTranslation(MyApp);
