import { Account } from "../schema/Account";
import { Budget } from "../schema/Budget";
import { JsQuery, JsSearch } from "../types/JsQuery";

export const sourceSearch = (account_id: Account["id"]): JsSearch => ({
  source_account_id: "=" + account_id,
});

export const targetSearch = (account_id: Account["id"]): JsSearch => ({
  source_account_id: "=" + account_id,
});

export const budgetSearch = (budget_id: Budget["id"]): JsSearch => ({
  budget_id: "=" + budget_id,
});

export const tAccountSearch = (account_id: Account["id"]): JsSearch => ({
  "||": [sourceSearch(account_id), targetSearch(account_id)],
});

export const incomeType = {
  source_account_id: "=null",
  target: { search: { type: "=!receivable" }, soft_deleted: true },
};

export const portfolioIncomeType = {
  source: { search: { type: "=receivable" }, soft_deleted: true },
  target: { search: { type: "=debit" }, soft_deleted: true },
};

export const expenseType = {
  source: { search: { type: "=debit;credit" }, soft_deleted: true },
  target: { search: { type: "=cost" }, soft_deleted: true },
};

export const incomeSearch = (extra: JsSearch): JsSearch => ({
  status: "=closed",
  ...incomeType,
  ...extra,
});

export const portfolioIncomeSearch = (extra: JsSearch): JsSearch => ({
  status: "=closed",
  ...portfolioIncomeType,
  ...extra,
});

export const expenseSearch = (extra: JsSearch): JsSearch => ({
  status: "=closed;debited",
  ...expenseType,
  ...extra,
});

export const batchQuery: JsQuery = {
  returns: ["count:value", "sum:value", "year:close_date", "month:close_date"],
  group_by: ["year_close_date", "month_close_date"],
};

export const transactionSearch = (search?: string): JsSearch => {
  if (!search) return {};
  const or: JsSearch[] = [
    { "source.reference": `=%${search}%` },
    { "source.description": `=%${search}%` },
    { "target.reference": `=%${search}%` },
    { "target.description": `=%${search}%` },
    { "tags.name": `=%${search}%` },
  ];

  if (/^\d+$/.test(search)) or.push({ value: `=${search}` });

  return { "&&": [{ description: `=%${search}%`, "||": or }] };
};
