import axios, { AxiosRequestConfig } from "axios";
import { getCookie } from "cookies-next";
import { OptionsType } from "cookies-next/lib/types";

export const authorize = (req?: OptionsType["req"]): AxiosRequestConfig => {
  const api_key = getCookie("__cost_track_id", { req });
  const company = getCookie("__cost_track_cn", { req });
  const Cookie = `__cost_track_id=${api_key}; __cost_track_cn=${company};`;
  return {
    headers: {
      Authorization: `Bearer ${api_key}`,
      ...(!process.platform ? {} : { Cookie }),
    },
  };
};

const api = axios.create({
  baseURL: !process.platform ? "/api" : "http://api",
});

api.interceptors.request.use((conf) => {
  if (!process.platform && !conf.headers?.["x-no-auth"]) {
    conf.headers.setAuthorization(`Bearer ${getCookie("__cost_track_id")}`);
  }
  return conf;
});

export const getApiClient = (opts: OptionsType) =>
  axios.create({ ...api.defaults, ...authorize(opts.req) });

export default api;
