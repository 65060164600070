import styled, { css } from "styled-components";

const colors = {
  gray: "#acacac",
  primary: "#f7b035",
  primaryFont: "#FFE6B9",
  primaryEmphasis: "#FFC969",
  darkFont: "#1B1202",
  dark: "#F79035",
};

const smallButtonCss = css`
  padding: ${(p) => p.theme.sizes.x1} ${(p) => p.theme.sizes.x4};
  border-radius: ${(p) => p.theme.sizes.x1};
  border: 1px solid ${(p) => p.theme.colors.darkGray};
`;

const buttonCss = css`
  width: 100%;
  font-weight: 600;
  padding: ${(p) => p.theme.sizes.x2};
  border-radius: ${(p) => p.theme.sizes.x2};
  font-size: ${(p) => p.theme.sizes.x4};
  border: 1px solid ${(p) => p.theme.colors.darkGray};
  transition: all 0.5s ease;
  cursor: pointer;
`;

const Button = styled.button<{ small?: boolean }>`
  ${(p) => (p.small ? smallButtonCss : buttonCss)};
  &:disabled,
  &:disabled&:hover {
    background-color: ${colors.gray};
  }
  &:not(:first-child) {
    margin-left: ${(p) => p.theme.sizes.x2};
  }
`;

export const ButtonPrimary = styled(Button)`
  color: white;
  background-color: ${colors.primary};
  &:hover {
    background: ${colors.primaryEmphasis};
  }
  &:active {
    background: ${colors.dark};
  }
`;

export const OutlinePrimary = styled(Button)`
  color: ${(p) => p.theme.colors.primary};
  background-color: unset;
  &:hover {
    color: ${(p) => p.theme.colors.primaryEmphasis};
    border-color: ${(p) => p.theme.colors.primaryEmphasis};
  }
  &:active {
    border-color: ${colors.dark};
  }
`;

export const RadioButton = styled.input<{ type: "radio" }>`
  display: none;
  & + label {
    display: block;
    padding: ${(p) => p.theme.sizes.x1} ${(p) => p.theme.sizes.x2};
    border: 1px solid ${(p) => p.theme.colors.primary};
    border-radius: ${(p) => p.theme.sizes.x1};
    text-align: center;
    margin-bottom: ${(p) => p.theme.sizes.x3};
  }

  &:active + label,
  &:checked + label {
    background-color: ${(p) => p.theme.colors.primary};
  }
`;
