import { Transaction } from "@castiero/web/schemas/Transaction";
import { forwardEvents } from "./eventEmitter";

const TRANSACTION_CREATED = "transaction_created";
const TRANSACTION_UPDATED = "transaction_updated";
const TRANSACTION_DELETED = "transaction_deleted";
const TRANSACTION_CONTEXT_CHANGED = "transaction_context_changed";

export type TransactionEventsType = {
  [TRANSACTION_CREATED]: Transaction;
  [TRANSACTION_UPDATED]: Transaction;
  [TRANSACTION_DELETED]: Transaction;
  [TRANSACTION_CONTEXT_CHANGED]: undefined;
};

forwardEvents(
  [TRANSACTION_CREATED, TRANSACTION_UPDATED, TRANSACTION_DELETED],
  TRANSACTION_CONTEXT_CHANGED
);

export default {
  TRANSACTION_CREATED,
  TRANSACTION_UPDATED,
  TRANSACTION_DELETED,
  TRANSACTION_CONTEXT_CHANGED,
} as const;
