import { TextField, BaseTextFieldProps } from "@mui/material";
import { useTranslation } from "next-i18next";
import React from "react";
import CurrencyInput from "react-currency-input-field";
import { Control, Controller } from "react-hook-form";

export const MuiCurrencyField = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    control?: Control<any>;
    error?: string;
  } & Omit<BaseTextFieldProps, "error">
>(({ label, control, error, ...props }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={props.name || ""}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            id="outlined-basic"
            label={label}
            variant="outlined"
            InputProps={{
              inputComponent: CurrencyInput as any,
              inputProps: {
                intlConfig: {
                  locale: t("currencyLocale"),
                  currency: t("currencyIso"),
                },
                onValueChange: onChange,
                value,
              },
            }}
            helperText={error}
            sx={{
              width: "100%",
              marginBottom: "20px",
            }}
            error={!!error}
            {...props}
          />
        );
      }}
    />
  );
});

MuiCurrencyField.displayName = "MuiCurrencyField";
