import React from "react";
import { MuiSelectField } from "./MuiSelectField";
import {
  DropdownOption,
  IIdentify,
  isTagOption,
} from "@castiero/web/store/types";
import {
  Autocomplete,
  Chip,
  Paper,
  SxProps,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";

interface DropdownProps<T extends IIdentify> {
  options: DropdownOption<T>[];
  label: string;
  control?: Control<any>;
  error?: string;
  name: string;
  multiple?: boolean;
  disabled?: boolean;
  size?: "small" | "medium";
  id?: string;
  actionForm?: (v: string) => void;
  search?: (e: { value: string }[]) => void;
  noPadding?: boolean;
  sx?: SxProps;
}

export const Dropdown = <T extends IIdentify>({
  options,
  control,
  name,
  label,
  multiple,
  disabled,
  error,
  actionForm,
  size,
  id,
  search,
  noPadding,
  sx,
}: DropdownProps<T>) => {
  const filter = createFilterOptions<
    DropdownProps<T> | { inputValue: string; label: string }
  >();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        value = (() => {
          if (multiple) {
            return Array.isArray(value)
              ? value
                  .map((v) => options.find((o) => o.value == v))
                  .filter(Boolean)
              : [];
          } else {
            return (
              options.find((o) => o.value === value) ?? {
                value: "",
                label: "",
              }
            );
          }
        })();

        return (
          <Autocomplete
            id={id}
            multiple={multiple}
            disabled={disabled}
            disablePortal
            options={options}
            value={value}
            size={size}
            freeSolo
            clearOnBlur
            getOptionLabel={(option) => option.label}
            onChange={(_, selected) => {
              const getLastSelected = (b: unknown) =>
                Array.isArray(b) ? b[b.length - 1] : b;

              if (!selected) return onChange(multiple ? [] : "");

              const last = getLastSelected(selected);
              if (last?.inputValue && actionForm) {
                return actionForm(last.inputValue);
              }

              if (multiple && Array.isArray(selected)) {
                if (search) {
                  search(selected.map((v) => v));
                }
                return onChange(selected.map((v) => v.value));
              }

              onChange(selected.value);
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== "") {
                if (!!actionForm && filtered.length === 0) {
                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }

              return filtered;
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index: number) => (
                <Chip
                  variant="filled"
                  label={option.label}
                  size="small"
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: isTagOption(option) ? option.color : "",
                    fontSize: "0.95rem",
                  }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                helperText={error}
                error={!!error}
                {...params}
                label={label}
                sx={{ backgroundColor: search ? "#fff" : "" }}
              />
            )}
            renderOption={(props, option) => (
              <Paper
                key={option.value}
                style={{
                  backgroundColor: option.color,
                  boxShadow: "none",
                }}
              >
                <Typography {...props}>{option.label}</Typography>
              </Paper>
            )}
            sx={{
              width: "100%",
              marginBottom: noPadding ? "0px" : "20px",
              ...sx,
            }}
          />
        );
      }}
    />
  );
};

MuiSelectField.displayName = "Dropdown";
