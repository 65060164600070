import { Budget } from "@castiero/web/schemas/Budget";
import { forwardEvents } from "./eventEmitter";

const BUDGET_CREATED = "budget_created";
const BUDGET_UPDATED = "budget_updated";
const BUDGET_DELETED = "budget_deleted";
const BUDGET_CONTEXT_CHANGED = "budget_context_changed";

export type BudgetEventsTypes = {
  [BUDGET_CREATED]: Budget;
  [BUDGET_UPDATED]: Budget;
  [BUDGET_DELETED]: Budget;
  [BUDGET_CONTEXT_CHANGED]: undefined;
};

forwardEvents(
  [BUDGET_CREATED, BUDGET_UPDATED, BUDGET_DELETED],
  BUDGET_CONTEXT_CHANGED
);

export default {
  BUDGET_CREATED,
  BUDGET_UPDATED,
  BUDGET_DELETED,
  BUDGET_CONTEXT_CHANGED,
} as const;
