import { useTranslation } from "next-i18next";
import useAppStore from "../useAppStore";
import byType from "@castiero/modules/schema/byType";
import { DropdownOption } from "../../store/types";
import { Account } from "../../schemas/Account";

export default function useTrxAccountOptions(source_value?: number | ""): {
  source: DropdownOption<Account>[];
  target: DropdownOption<Account>[];
} {
  const { t } = useTranslation();
  const { options } = useAppStore("accounts");
  const active = byType(options, "cost", "credit", "debit");

  let target: DropdownOption<Account>[] = [];
  if (source_value === 0) {
    target = byType(active, "debit", "credit");
  } else if (source_value) {
    target = byType(active, "debit", "credit", "cost").filter(
      (item) => item.value != source_value
    );
  }

  return {
    source: [
      { value: 0, label: t("income"), type: "debit" },
      ...byType(active, "debit", "credit"),
    ],
    target,
  };
}
