import { User } from "@castiero/web/schemas/User";
import { Company } from "@castiero/web/schemas/Company";
import { Tag } from "@castiero/web/schemas/Tag";
import { Account } from "@castiero/web/schemas/Account";
import { Budget } from "@castiero/web/schemas/Budget";

export type StoreStateType = "idle" | "loading" | "loaded" | "error";

export interface IIdentify {
  id: string | number;
}

export type DropdownOption<T extends IIdentify> = T extends Tag
  ? {
      value: T["id"];
      label: string;
      color: string;
    }
  : T extends Account
  ? {
      value: T["id"];
      label: string;
      type: Account["type"];
    }
  : T extends Budget
  ? {
      value: T["id"];
      label: string;
      target_account_id: Account["id"];
    }
  : {
      value: T["id"];
      label: string;
    };

export const isTagOption = (d: DropdownOption<any>): d is DropdownOption<Tag> =>
  "color" in d;

// pagination data, the page date, dropdown field data
export type NotAuthorized = { authorized: false };
export type AuthorizedUser = { authorized: true; user: User };
export type AuthorizedCompany = {
  authorized: true;
  user: User;
  company: Company;
};

export type AuthStoreType = NotAuthorized | AuthorizedUser | AuthorizedCompany;

export const isAuthorized = (auth: any): auth is AuthorizedUser =>
  "authorized" in auth && auth.authorized && "user" in auth;

export const isAuthorizedCompany = (d: any): d is AuthorizedCompany =>
  "authorized" in d && d.authorized && "company" in d;
