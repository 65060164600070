import { useApiService } from "@castiero/web/api/apiService";
import { colors, theme } from "@castiero/web/common/theme";
import { toastSaveParams } from "@castiero/web/common/toast";
import {
  AccountForm,
  AccountFormType,
  initAccount,
} from "@castiero/web/components/forms/AccountForm";

import { ButtonPrimary } from "@castiero/web/components/buttons";
import { Account, AccountCast } from "@castiero/web/schemas/Account";

import { useTranslation } from "next-i18next";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Row, Col } from "react-grid-system";
import { toast } from "react-toastify";
import { FormContext } from "../common/types";

const AccountFormContext = createContext<FormContext<Account, AccountFormType>>(
  undefined as never
);

export const AccountFormProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState<AccountFormType>();
  const toggle = useCallback(
    (_active: boolean, account?: Partial<AccountFormType>) => {
      setActive(_active != active);
      setValues({ ...initAccount, ...account });
    },
    [active]
  );

  const { t } = useTranslation();
  const apiService = useApiService();

  const state = useMemo(
    () => ({ active, values, toggle }),
    [active, values, toggle]
  );

  const onSubmit = async (data: AccountFormType) => {
    await toast.promise(
      apiService.accounts.save(AccountCast(data)),
      toastSaveParams("account", t)
    );
    setActive(false);
  };

  return (
    <AccountFormContext.Provider value={state}>
      {children}
      {active ? (
        <AccountForm
          defaults={values}
          state={true}
          setState={setActive}
          onSubmit={onSubmit}
        >
          {(reset, create) => (
            <Row>
              <Col>
                <ButtonPrimary
                  id="cancel-account-form-button"
                  type="button"
                  onClick={() => {
                    reset();
                    setActive(false);
                  }}
                  style={{ backgroundColor: theme.palette.error.main }}
                >
                  {t("cancel")}
                </ButtonPrimary>
              </Col>
              <Col>
                <ButtonPrimary
                  id="create-account-form-button"
                  type="submit"
                  style={{ backgroundColor: colors.success }}
                >
                  {!create ? t("save") : t("create")}
                </ButtonPrimary>
              </Col>
            </Row>
          )}
        </AccountForm>
      ) : null}
    </AccountFormContext.Provider>
  );
};

export const useAccountForm = () => {
  const context = useContext(AccountFormContext);
  if (!context) {
    throw new Error("useAccountForm must be used within a AccountFormProvider");
  }
  return context;
};
