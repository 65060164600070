import { budgetsFrequencyOptions } from "@castiero/web/api/constants";
import useAppStore from "@castiero/web/hooks/useAppStore";
import { Budget, BudgetSchema } from "@castiero/web/schemas/Budget";
import { FormChildrenProp, FormValues } from "@castiero/web/schemas/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material";
import { defaults } from "lodash";
import { useTranslation } from "next-i18next";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Dropdown } from "../fields/Dropdown";
import { MuiCheckBox } from "../fields/MuiCheckBox";
import { MuiCurrencyField } from "../fields/MuiCurrencyField";
import { MuiField } from "../fields/MuiField";
import { MuiSelectField } from "../fields/MuiSelectField";
import { Col, Row } from "../grid";

import byType from "@castiero/modules/schema/byType";
import { useApiService } from "@castiero/web/api/apiService";
import events from "@castiero/web/common/events";
import { useAccountForm } from "@castiero/web/context/forms/account";
import { useTagForm } from "@castiero/web/context/forms/tag";
import { Account } from "@castiero/web/schemas/Account";
import { Tag } from "@castiero/web/schemas/Tag";
import { FocusTrap } from "@mui/base";
import ModalHeader from "../MUI/modals/Header";
import { ModalBox } from "../styled/modal";
import useTrxAccountOptions from "../../hooks/forms/useTrxAccountOptions";

type BudgetTypes = {
  type: "income" | "transference" | "expense" | "loan";
};

export type BudgetFormType = FormValues<Budget & BudgetTypes>;

export const initBudget: FormValues<Budget> = {
  id: "",
  description: "",
  value: "",
  frequency: "monthly",
  payout_day: "",
  source_account_id: "",
  target_account_id: "",
  company_id: "",
  tag_ids: [],
};

export const getInitBudget = (
  bg: Partial<BudgetFormType> = {}
): BudgetFormType => ({
  ...initBudget,
  ...bg,
  type: "",
  source_account_id: bg.id ? bg.source_account_id ?? 0 : "",
});

export const BudgetForm: React.FC<FormChildrenProp<Budget & BudgetTypes>> = ({
  onSubmit,
  children,
  defaults: defaultValues,
  state,
  setState,
}) => {
  const tags = useAppStore("tags");
  const accounts = useAppStore("accounts");
  const tagForm = useTagForm();
  const accountForm = useAccountForm();
  const apiService = useApiService();

  const localDefaultValues = defaults(defaultValues, initBudget);
  const methods = useForm<BudgetFormType>({
    resolver: yupResolver(BudgetSchema),
    defaultValues: { ...localDefaultValues },
  });

  const accOptions = byType(accounts.options, "debit", "credit", "cost");

  const {
    id: budget_id,
    type,
    tag_ids,
    target_account_id,
    source_account_id,
  } = methods.watch();
  const { formState, reset } = methods;
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    const account = accOptions.find((a) => a.value === target_account_id);
    if (account?.type === "credit") {
      apiService.accounts.getItem(account.value).then((account) => {
        if (mounted)
          methods.setValue("payout_day" as const, account.payout_day);
      });
    }
    return () => {
      mounted = false;
    };
  }, [target_account_id, accOptions]);

  const { source, target } = useTrxAccountOptions(source_account_id);

  const tagOptions = tags.options;

  const onSourceAccount = (account: Account) => {
    methods.setValue("source_account_id" as const, account.id, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };
  const onTargetAccount = (account: Account) =>
    methods.setValue("target_account_id" as const, account.id, {
      shouldDirty: true,
      shouldTouch: true,
    });

  const onTag = (tag: Tag) => {
    if (Array.isArray(tag_ids)) {
      methods.setValue("tag_ids" as const, [...tag_ids, String(tag.id)], {
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      methods.setValue("tag_ids" as const, [String(tag.id)], {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  return (
    <FocusTrap disableAutoFocus open>
      <Modal
        open={!!state}
        onClose={() => setState?.(false)}
        disableEnforceFocus
      >
        <ModalBox>
          <ModalHeader
            title={
              localDefaultValues.id ? t("edit_budget") : t("create_new_budget")
            }
            onClose={() => setState?.(false)}
          />

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Row className={`budget-form-${type}`}>
                <Col md={6}>
                  <Dropdown
                    id="budget-source-account"
                    actionForm={(txt) => {
                      accountForm.toggle(true, { description: txt });
                      events.off(events.ACCOUNT_CREATED, onTargetAccount);
                      events.once(events.ACCOUNT_CREATED, onSourceAccount);
                    }}
                    label={t("source_account")}
                    options={source}
                    disabled={type == "income"}
                    name="source_account_id"
                    error={formState.errors.source_account_id?.message}
                  />
                </Col>
                <Col md={6}>
                  <Dropdown
                    id="budget-target-account"
                    actionForm={(txt) => {
                      accountForm.toggle(true, { description: txt });
                      events.off(events.ACCOUNT_CREATED, onSourceAccount);
                      events.once(events.ACCOUNT_CREATED, onTargetAccount);
                    }}
                    label={t("target_account")}
                    options={target}
                    name="target_account_id"
                    error={formState.errors.target_account_id?.message}
                  />
                </Col>
                <Col md={6}>
                  <MuiField
                    label={t("description")}
                    placeholder={t(`description_${type}`)}
                    name="description"
                    id="budget-description"
                    error={formState.errors.description?.message}
                  />
                </Col>
                <Col md={6}>
                  <MuiCurrencyField
                    label={t("value")}
                    placeholder={t(`value_${type}`)}
                    name="value"
                    id="budget-value"
                    error={formState.errors.value?.message}
                  />
                </Col>
                <Col md={6}>
                  <MuiSelectField
                    label={t("frequency")}
                    options={budgetsFrequencyOptions}
                    name="frequency"
                    id="budget-frequency"
                    error={formState.errors.frequency?.message}
                  />
                </Col>
                <Col md={6}>
                  <MuiField
                    label={t("payout_day")}
                    placeholder={t(`payout_day_${type}`)}
                    name="payout_day"
                    id="budget-payout_day"
                    error={formState.errors.payout_day?.message}
                  />
                </Col>
                <Col md={6}>
                  <Dropdown
                    id="budget-tag-ids"
                    actionForm={(txt) => {
                      tagForm.toggle(true, { name: txt });
                      events.once(events.TAG_CREATED, onTag);
                    }}
                    label={t("tags")}
                    options={tagOptions}
                    name="tag_ids"
                    multiple
                    error={formState.errors.tag_ids?.message}
                  />
                </Col>
                <Col md={6}>
                  <MuiCheckBox
                    id="budget-alerts-enabled"
                    label={t`alerts_enabled`}
                    name="alerts_enabled"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {children(
                    () => reset(localDefaultValues),
                    !budget_id,
                    methods.formState
                  )}
                </Col>
              </Row>
            </form>
          </FormProvider>
        </ModalBox>
      </Modal>
    </FocusTrap>
  );
};
