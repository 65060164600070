import api from "@castiero/web/api";
import { actions } from "@castiero/web/store";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { ApiServiceDeps } from "./types";
import { authServiceProvider } from "./authServiceProvider";
import { fullRestServiceProvider } from "./fullRestServiceProvider";
import { access } from "./waitlist";
import ev, { EventKeys } from "@castiero/web/common/events";
import cashflowServiceProvider from "@castiero/modules/api/service/cashflowServiceFactory";

export const useApiService = () => {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      apiServiceFactory({
        dispatch,
        axios: api,
      }),
    []
  );
};

const createFullRestEvents = (
  onCreate: EventKeys,
  onUpdate: EventKeys,
  onDelete: EventKeys
) => ({ onCreate, onUpdate, onDelete } as const);

export const apiServiceFactory = (deps: ApiServiceDeps) => ({
  auth: authServiceProvider(
    deps,
    actions.auth(deps.dispatch),
    actions.companies(deps.dispatch)
  ),
  cashflow: cashflowServiceProvider(deps, actions.cashflow(deps.dispatch)),
  accounts: fullRestServiceProvider(
    deps,
    "accounts",
    actions.accounts(deps.dispatch),
    createFullRestEvents(
      ev.ACCOUNT_CREATED,
      ev.ACCOUNT_UPDATED,
      ev.ACCOUNT_DELETED
    )
  ),
  budgets: fullRestServiceProvider(
    deps,
    "budgets",
    actions.budgets(deps.dispatch),
    createFullRestEvents(
      ev.BUDGET_CREATED,
      ev.BUDGET_UPDATED,
      ev.BUDGET_DELETED
    )
  ),
  companies: fullRestServiceProvider(
    deps,
    "companies",
    actions.companies(deps.dispatch),
    createFullRestEvents(
      ev.COMPANY_CREATED,
      ev.COMPANY_UPDATED,
      ev.COMPANY_DELETED
    )
  ),
  customers: fullRestServiceProvider(
    deps,
    "customers",
    actions.customers(deps.dispatch),
    createFullRestEvents(
      ev.CUSTOMER_CREATED,
      ev.CUSTOMER_UPDATED,
      ev.CUSTOMER_DELETED
    )
  ),
  customerContacts: fullRestServiceProvider(
    deps,
    "customer-contacts",
    actions.customer_contacts(deps.dispatch),
    createFullRestEvents(
      ev.CUSTOMER_CONTACT_CREATED,
      ev.CUSTOMER_CONTACT_UPDATED,
      ev.CUSTOMER_CONTACT_DELETED
    )
  ),
  transactions: fullRestServiceProvider(
    deps,
    "transactions",
    actions.transactions(deps.dispatch),
    createFullRestEvents(
      ev.TRANSACTION_CREATED,
      ev.TRANSACTION_UPDATED,
      ev.TRANSACTION_DELETED
    )
  ),
  users: fullRestServiceProvider(
    deps,
    "users",
    actions.users(deps.dispatch),
    createFullRestEvents(ev.USER_CREATED, ev.USER_UPDATED, ev.USER_DELETED)
  ),
  waitlist: {
    ...fullRestServiceProvider(
      deps,
      "waitlist",
      actions.waitlist(deps.dispatch)
    ),
    access: access(deps, actions.waitlist(deps.dispatch)),
  },
  tags: fullRestServiceProvider(
    deps,
    "tags",
    actions.tags(deps.dispatch),
    createFullRestEvents(ev.TAG_CREATED, ev.TAG_UPDATED, ev.TAG_DELETED)
  ),
  import_models: fullRestServiceProvider(
    deps,
    "import-models",
    actions.import_models(deps.dispatch)
  ),
  defaultCostCenters: fullRestServiceProvider(
    deps,
    "default-cost-centers",
    actions.defaultCostCenter(deps.dispatch)
  ),
});

export type ApiServiceType = ReturnType<typeof apiServiceFactory>;
