import { ITransnational } from "@castiero/web/model/transactional";
import { FormValues, numberSchema, Shape } from "./common";
import { Company } from "./Company";
import { Account } from "./Account";
import { Tag } from "./Tag";
import * as yup from "yup";

export type BudgetFrequency =
  | "weekly"
  | "biweekly"
  | "monthly"
  | "bimonthly"
  | "quarterly"
  | "biannual"
  | "annual";

export interface Budget extends ITransnational {
  id: number;
  description: string;
  frequency: BudgetFrequency;
  value: number;
  payout_day: number;
  source_account_id?: Account["id"];
  target_account_id: Account["id"];
  company_id: Company["id"];
  account?: Account;
  tag_ids?: string[];
  tags?: Tag[];
  alerts_enabled?: boolean;
  readonly monthly: number;
  readonly expensed: number;
}

export const BudgetSchema = yup.object<Shape<FormValues<Budget>>>().shape({
  id: numberSchema,
  description: yup.string().required(),
  value: numberSchema.required(),
  payout_day: numberSchema.required(),
  frequency: yup.string().required(),
  source_account_id: numberSchema.when("type", {
    is: "income",
    otherwise: numberSchema.required(),
  }),
  target_account_id: numberSchema.required(),
});

export const BudgetCast = (data: unknown): Budget => {
  if (!isBudget(data)) throw new Error("Data is not a budget");
  return BudgetSchema.cast(data) as Budget;
};

export const isBudget = (data: unknown): data is Budget =>
  BudgetSchema.isValidSync(data);
