import { forwardEvents } from "./eventEmitter";
import { CustomerContact } from "@castiero/modules/schema/CustomerContact";

const CUSTOMER_CONTACT_CREATED = "customer_contact_created";
const CUSTOMER_CONTACT_UPDATED = "customer_contact_updated";
const CUSTOMER_CONTACT_DELETED = "customer_contact_deleted";
const CUSTOMER_CONTACT_CONTEXT_CHANGED = "customer_contact_context_changed";

export type CustomerContactEventsType = {
  [CUSTOMER_CONTACT_CREATED]: CustomerContact;
  [CUSTOMER_CONTACT_UPDATED]: CustomerContact;
  [CUSTOMER_CONTACT_DELETED]: CustomerContact;
  [CUSTOMER_CONTACT_CONTEXT_CHANGED]: undefined;
};

forwardEvents(
  [
    CUSTOMER_CONTACT_CREATED,
    CUSTOMER_CONTACT_UPDATED,
    CUSTOMER_CONTACT_DELETED,
  ],
  CUSTOMER_CONTACT_CONTEXT_CHANGED
);

export default {
  CUSTOMER_CONTACT_CREATED,
  CUSTOMER_CONTACT_UPDATED,
  CUSTOMER_CONTACT_DELETED,
  CUSTOMER_CONTACT_CONTEXT_CHANGED,
} as const;
