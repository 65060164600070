import {
  JsQuery,
  JsSearch,
  JsBatchType,
} from "@castiero/modules/types/JsQuery";
import {
  expenseSearch,
  incomeSearch,
  portfolioIncomeSearch,
} from "../../js_query/transactions";
import { ObjectStoreActionType } from "../../redux/createObjectStore";
import { BatchItem } from "../../types/BatchItem";
import { Cashflow } from "../../types/Cashflow";
import { ApiServiceDeps } from "../types";
import tagsQuery from "../../js_query/tags";
import jsQueryServiceFactory from "./jsQueryServiceFactory";
import { DateRange, rangeQuery } from "../../js_query/dates";
import dayjs from "dayjs";

const baseQuery: JsQuery = {
  returns: ["year:close_date", "month:close_date", "sum:value", "count:value"],
  group_by: ["year_close_date", "month_close_date"],
};

const parceBaseType = (item: JsBatchType): BatchItem => ({
  sum: item.sum_value,
  count: item.count_value,
  year: Number(item.year_close_date),
  month: Number(item.month_close_date),
});

export default function cashflowServiceProvider(
  deps: ApiServiceDeps,
  actions: ObjectStoreActionType<Cashflow>
) {
  return {
    async loadData(params?: { tags: string[]; range?: DateRange }) {
      actions.setLoading();
      const JsQuery = jsQueryServiceFactory(deps)("transactions");
      const tags = tagsQuery(params?.tags);
      const range = rangeQuery("close_date", params?.range);

      const incomeQuery = (range: JsSearch = {}): JsQuery => ({
        search: {
          "||": [
            incomeSearch({ ...tags, ...range }),
            portfolioIncomeSearch({ ...tags, ...range }),
          ],
        },
      });

      const expenseQuery = (range: JsSearch = {}): JsQuery => ({
        search: expenseSearch({ ...tags, ...range }),
      });

      const batchRange = rangeQuery("close_date", [
        dayjs().subtract(6, "M").startOf("M").toDate(),
        new Date(),
      ]);

      try {
        const data = await Promise.all([
          JsQuery<JsBatchType>({
            returns: ["sum:value"],
            ...incomeQuery(range),
          }),
          JsQuery<JsBatchType>({
            returns: ["sum:value"],
            ...expenseQuery(range),
          }),
          JsQuery<JsBatchType>({ ...incomeQuery(batchRange), ...baseQuery }),
          JsQuery<JsBatchType>({ ...expenseQuery(batchRange), ...baseQuery }),
        ]).then(
          ([
            { data: income },
            { data: expense },
            { data: income_batch },
            { data: expense_batch },
          ]) =>
            ({
              incoming: income[0]?.sum_value || 0,
              expenses: expense[0]?.sum_value || 0,
              incoming_batch: income_batch.map(parceBaseType),
              expenses_batch: expense_batch.map(parceBaseType),
            } as Cashflow)
        );
        actions.setLoaded(data);
      } catch (e) {
        actions.setError({ message: "error_getting_cashflow" });
      }
    },
  };
}
