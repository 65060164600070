import { FormChildrenProp, FormValues } from "@castiero/web/schemas/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal } from "@mui/material";
import { defaults } from "lodash";
import { useTranslation } from "next-i18next";
import React from "react";
import { MuiField } from "../fields/MuiField";
import { Company, CompanySchema } from "@castiero/web/schemas/Company";
import { useForm } from "react-hook-form";
import { Col } from "react-grid-system";
import { FocusTrap } from "@mui/base";
import { ModalBox } from "../styled/modal";
import ModalHeader from "../MUI/modals/Header";

export type CompanyFormType = FormValues<Company>;

export const initCompany: CompanyFormType = {
  id: "",
  name: "",
  email: "",
  is_individual: false,
};

export const CompanyForm: React.FC<FormChildrenProp<Company>> = ({
  onSubmit,
  children,
  defaults: defaultValues,
  state,
  setState,
}) => {
  const localDefaultValues = defaults(defaultValues, initCompany);
  const methods = useForm<FormValues<Company>>({
    resolver: yupResolver(CompanySchema),
    defaultValues: localDefaultValues,
  });
  const { formState, control, handleSubmit, reset } = methods;
  const [id] = methods.watch(["id"] as const);

  const { t } = useTranslation();

  return (
    <FocusTrap disableAutoFocus open>
      <Modal
        open={!!state}
        onClose={() => setState?.(false)}
        disableEnforceFocus
      >
        <ModalBox>
          <ModalHeader
            title={t("create_company")}
            onClose={() => setState?.(false)}
          />
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <MuiField
              control={control}
              label={t("name")}
              name="name"
              id="new_company.name"
              type="string"
              error={formState.errors.name?.message}
            />
            <MuiField
              control={control}
              label={t("email")}
              name="email"
              id="new_company.email"
              type="string"
              error={formState.errors.email?.message}
            />
            <Col xs={12}>
              {children(
                () => reset(localDefaultValues),
                !id,
                methods.formState
              )}
            </Col>
          </Box>
        </ModalBox>
      </Modal>
    </FocusTrap>
  );
};
