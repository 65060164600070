import * as yup from "yup";
import { numberSchema } from "./common";

// Types
import { FormValues } from "../types/FormValues";
import { Shape } from "../types/yup";

export type AccountType = "debit" | "credit" | "cost" | "receivable";

export interface Account {
  id: number;
  type: AccountType;
  description: string;
  reference: string;
  bank: string;
  billing_day: number;
  payout_day: number;
  interest_factor: number;
  from_first_payment: boolean;
  balance: number;
  company_id: string;
  display: string;
  percentage: number;
  readonly default_cost_center_id: number;
  // readonly budgets: Array<Budget>;
  readonly expensed: number;
}

export const AccountSchema = yup.lazy<
  yup.ObjectSchema<Shape<FormValues<Account>>>
>(({ type }: Account) => {
  const baseSchema = yup.object<Shape<FormValues<Account>>>().shape({
    id: numberSchema,
    type: yup.string().required(),
    reference: yup.string().required(),
    description: yup.string().required(),
  });

  switch (type) {
    case "cost": {
      return baseSchema.concat(
        yup.object().shape({
          percentage: numberSchema.typeError("Percentage must be a number"),
          default_cost_center_id: numberSchema,
        })
      );
    }

    case "debit": {
      return baseSchema.concat(
        yup.object().shape({
          balance: numberSchema.typeError("Balance must be a number"),
          bank: yup
            .string()
            .typeError("Bank must have a valid bank name")
            .required(),
        })
      );
    }
    case "credit": {
      return baseSchema.concat(
        yup.object<Shape<FormValues<Account>>>().shape({
          balance: numberSchema
            .typeError("Balance must be a number")
            .required(),
          bank: yup
            .string()
            .typeError("Bank must have a valid bank name")
            .required(),
          billing_day: yup
            .number()
            .typeError("The billing day must be a valid day")
            .required(),
          payout_day: yup
            .number()
            .typeError("The payout day must be a valid day")
            .required(),
          interest_factor: yup
            .number()
            .typeError("The interest factor must be a valid percentage number")
            .required(),
        })
      );
    }
    case "receivable":
    default: {
      return baseSchema;
    }
  }
});

export const AccountCast = (data: unknown) =>
  AccountSchema.cast(data, { stripUnknown: true }) as Account;

export const isAccount = (data: unknown): data is Account =>
  AccountSchema.isValidSync(data);
