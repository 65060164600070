import { Tag } from "@castiero/web/schemas/Tag";
import { forwardEvents } from "./eventEmitter";

const TAG_CREATED = "tag_created";
const TAG_UPDATED = "tag_updated";
const TAG_DELETED = "tag_deleted";
const TAG_CONTEXT_CHANGED = "tag_context_changed";

export type TagEventsType = {
  [TAG_CREATED]: Tag;
  [TAG_UPDATED]: Tag;
  [TAG_DELETED]: Tag;
  [TAG_CONTEXT_CHANGED]: undefined;
};

forwardEvents([TAG_CREATED, TAG_UPDATED, TAG_DELETED], TAG_CONTEXT_CHANGED);

export default {
  TAG_CREATED,
  TAG_UPDATED,
  TAG_DELETED,
  TAG_CONTEXT_CHANGED,
} as const;
