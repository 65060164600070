import { number } from "yup";

export interface UserCompany {
  company_id: string;
  created_at: Date;
  role: "owner" | "admin" | "manager";
  cash_account_id?: number;
  updated_at: Date;
  user_id: number;
}

export const numberSchema = number()
  // checking self-equality works for NaN, transforming it to null
  .transform((_, val) => (/\d+/.test(String(val)) ? Number(val) : undefined));
