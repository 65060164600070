import { ITransnational } from "@castiero/web/model/transactional";
import { FormValues, numberSchema, Shape } from "./common";
import { Company } from "./Company";
import { Budget } from "./Budget";
import { Tag } from "./Tag";
import * as yup from "yup";
import { Account } from "@castiero/modules/schema/Account";

export type TransactionStatus = "budgeted" | "debited" | "closed";

export interface Transaction extends ITransnational {
  id: number;
  description: string;
  value: number;
  status: TransactionStatus;
  due_date?: string;
  close_date?: string;
  source_account_id?: Account["id"];
  target_account_id: Account["id"];
  company_id: Company["id"];
  budget_id?: Budget["id"];
  tag_ids?: string[];
  tags?: Tag[];
}

export const TransactionSchema = yup
  .object<Shape<FormValues<Transaction>>>()
  .shape({
    id: numberSchema,
    budget_id: numberSchema,
    description: yup.string().required(),
    value: numberSchema.required(),
    status: yup.string().required(),
    due_date: yup.date().required(),
    close_date: yup.date().when("status", {
      is: "closed",
      then: yup.date().required(),
    }),
    source_account_id: numberSchema.when("type", {
      is: "income",
      otherwise: numberSchema.required(),
    }),
    target_account_id: numberSchema.required(),
    tag_ids: yup.array(yup.string()).nullable(),
  });

export const TransactionCast = (data: unknown) =>
  TransactionSchema.cast(data, { stripUnknown: true }) as Transaction;
export const isTransaction = (data: unknown): data is Transaction =>
  TransactionSchema.isValidSync(data);
