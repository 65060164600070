import { Col as ParentCol, Row as ParentRow } from "react-grid-system";
import styled, { css } from "styled-components";

export const Col = styled(ParentCol)<{ right?: boolean; center?: boolean }>`
  ${(p) =>
    p.right &&
    css`
      text-align: right;
    `}
  ${(p) =>
    p.center &&
    css`
      text-align: center;
    `}
`;

export const Row = styled(ParentRow)<{
  highlight?: boolean;
  vertical?: boolean;
}>`
  ${(p) =>
    p.highlight &&
    css`
      &:hover {
        background-color: ${(p) => p.theme.colors.lightGrey};
      }
    `}
  ${(p) =>
    p.vertical &&
    css`
      flex-flow: column !important;
    `}
`;
